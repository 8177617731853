import React, { useState } from 'react';
import {
  Stack,
  Text,
  Separator,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  DefaultButton,
  ActionButton,
  Pivot,
  PivotItem,
  Persona,
  PersonaSize,
  List,
  IconButton
} from '@fluentui/react';
import useGraphData from '../../hooks/useGraphData';
import { useNotification } from '../../context/NotificationContext';
import './TeamDetail.css';

const TeamDetail = ({ teamId }) => {
  const { data: team, loading: teamLoading, error: teamError } = useGraphData(`/teams/${teamId}`);
  const { data: channels, loading: channelsLoading, error: channelsError } = useGraphData(`/teams/${teamId}/channels`);
  const { data: members, loading: membersLoading, error: membersError } = useGraphData(`/teams/${teamId}/members`);
  const { showNotification } = useNotification();
  
  const [selectedPivotKey, setSelectedPivotKey] = useState('channels');
  
  const isLoading = teamLoading || channelsLoading || membersLoading;
  const hasError = teamError || channelsError || membersError;
  
  // Teams에서 팀 열기
  const handleOpenInTeams = () => {
    if (team && teamId) {
      const deepLink = `https://teams.microsoft.com/l/team/${teamId}/conversations?groupId=${teamId}`;
      window.open(deepLink, '_blank');
      showNotification({
        message: `${team.displayName || '팀'}을 Teams에서 열고 있습니다...`,
        type: 'info'
      });
    }
  };
  
  // 채널 열기
  const handleOpenChannel = (channel) => {
    if (channel && channel.id && teamId) {
      const deepLink = `https://teams.microsoft.com/l/channel/${channel.id}/${encodeURIComponent(channel.displayName)}?groupId=${teamId}`;
      window.open(deepLink, '_blank');
      showNotification({
        message: `${channel.displayName || '채널'}을 Teams에서 열고 있습니다...`,
        type: 'info'
      });
    } else {
      showNotification({
        message: 'Teams에서 채널을 열 수 없습니다. 채널 정보가 부족합니다.',
        type: 'error'
      });
    }
  };
  
  // 사용자와 채팅 시작
  const handleChatWithMember = (member) => {
    if (member && member.email) {
      const deepLink = `https://teams.microsoft.com/l/chat/0/0?users=${member.email}`;
      window.open(deepLink, '_blank');
      showNotification({
        message: `${member.displayName || '사용자'}와의 채팅을 시작합니다...`,
        type: 'success'
      });
    } else {
      showNotification({
        message: '채팅을 시작할 수 없습니다. 사용자 정보가 부족합니다.',
        type: 'error'
      });
    }
  };
  
  // 채널 목록 렌더링
  const renderChannels = () => {
    if (!channels || channels.length === 0) {
      return <Text>채널이 없습니다.</Text>;
    }
    
    return (
      <div className="channels-list">
        {channels.map(channel => (
          <div key={channel.id} className="channel-item">
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
              <Stack.Item grow>
                <Text variant="mediumPlus">{channel.displayName || '(이름 없음)'}</Text>
                {channel.description && (
                  <Text className="channel-description">{channel.description}</Text>
                )}
              </Stack.Item>
              <IconButton
                iconProps={{ iconName: 'TeamsLogo' }}
                title="Teams에서 열기"
                onClick={() => handleOpenChannel(channel)}
              />
            </Stack>
          </div>
        ))}
      </div>
    );
  };
  
  // 멤버 목록 렌더링
  const renderMembers = () => {
    if (!members || members.length === 0) {
      return <Text>멤버가 없습니다.</Text>;
    }
    
    return (
      <div className="members-list">
        {members.map(member => (
          <div key={member.id} className="member-item">
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
              <Persona
                text={member.displayName || '(이름 없음)'}
                secondaryText={member.email || ''}
                size={PersonaSize.size32}
              />
              <IconButton
                iconProps={{ iconName: 'Chat' }}
                title="채팅 시작"
                onClick={() => handleChatWithMember(member)}
              />
            </Stack>
          </div>
        ))}
      </div>
    );
  };
  
  if (isLoading) {
    return (
      <div className="loading-container">
        <Spinner size={SpinnerSize.large} label="팀 정보를 불러오는 중..." />
      </div>
    );
  }
  
  if (hasError) {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        오류가 발생했습니다: {teamError || channelsError || membersError}
      </MessageBar>
    );
  }
  
  if (!team) {
    return (
      <MessageBar messageBarType={MessageBarType.warning}>
        팀 정보를 찾을 수 없습니다.
      </MessageBar>
    );
  }
  
  return (
    <div className="team-detail">
      <div className="team-header">
        <Text variant="xLarge" className="team-name">
          {team.displayName || '(이름 없음)'}
        </Text>
        
        {team.description && (
          <Text className="team-description">{team.description}</Text>
        )}
      </div>
      
      <div className="team-actions">
        <ActionButton iconProps={{ iconName: 'TeamsLogo' }} onClick={handleOpenInTeams}>
          Teams에서 열기
        </ActionButton>
      </div>
      
      <Separator />
      
      <Pivot
        selectedKey={selectedPivotKey}
        onLinkClick={(item) => setSelectedPivotKey(item.props.itemKey)}
        className="team-pivot"
      >
        <PivotItem headerText="채널" itemKey="channels">
          <div className="pivot-content">
            <Text variant="mediumPlus" className="section-title">
              채널 ({channels.length})
            </Text>
            {renderChannels()}
          </div>
        </PivotItem>
        
        <PivotItem headerText="멤버" itemKey="members">
          <div className="pivot-content">
            <Text variant="mediumPlus" className="section-title">
              멤버 ({members.length})
            </Text>
            {renderMembers()}
          </div>
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default TeamDetail; 
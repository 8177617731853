import React, { useState } from 'react';
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    Text,
    SearchBox,
    Stack,
    DefaultButton,
    Dropdown,
    DatePicker,
    mergeStyleSets
} from '@fluentui/react';
import { useOutletContext } from 'react-router-dom';
import useGraphData from '../../hooks/useGraphData';
import CalendarEventDetail from './CalendarEventDetail';
import './CalendarPage.css';

const CalendarPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const { openDetailPanel } = useOutletContext();

    // startDate를 ISO 문자열로 변환
    const startDateIso = startDate.toISOString();

    // 한 달 후의 날짜 계산
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);
    const endDateIso = endDate.toISOString();

    // 이벤트 필터 쿼리 구성
    const calendarQuery = `/me/calendarView?startDateTime=${startDateIso}&endDateTime=${endDateIso}`;

    const { data: events, loading, error, refresh } = useGraphData(calendarQuery);

    // 이벤트 필터링
    const filteredEvents = events.filter(event =>
        event.subject?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.bodyPreview?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location?.displayName?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // 이벤트 목록 컬럼 정의
    const columns = [
        {
            key: 'subject',
            name: '제목',
            fieldName: 'subject',
            minWidth: 200,
            isMultiline: true,
            onRender: (item) => item.subject || '(제목 없음)'
        },
        {
            key: 'start',
            name: '시작 시간',
            fieldName: 'start',
            minWidth: 120,
            maxWidth: 120,
            onRender: (item) => new Date(item.start.dateTime).toLocaleString()
        },
        {
            key: 'end',
            name: '종료 시간',
            fieldName: 'end',
            minWidth: 120,
            maxWidth: 120,
            onRender: (item) => new Date(item.end.dateTime).toLocaleString()
        },
        {
            key: 'location',
            name: '장소',
            fieldName: 'location',
            minWidth: 150,
            maxWidth: 180,
            onRender: (item) => item.location?.displayName || '(장소 없음)'
        },
        {
            key: 'actions',
            name: '작업',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item) => (
                <DefaultButton
                    text="보기"
                    onClick={() => handleViewEvent(item)}
                />
            )
        }
    ];

    // 이벤트 상세 보기
    const handleViewEvent = (event) => {
        openDetailPanel(`일정: ${event.subject || '제목 없음'}`, <CalendarEventDetail event={event} />);
    };

    // 날짜 변경 핸들러
    const handleDateChange = (date) => {
        if (date) {
            setStartDate(date);
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <Spinner size={SpinnerSize.large} label="일정을 불러오는 중..." />
            </div>
        );
    }

    if (error) {
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                오류가 발생했습니다: {error}
            </MessageBar>
        );
    }

    return (
        <div className="calendar-page">
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="page-header">
                <Text variant="xxLarge">캘린더</Text>
                <DefaultButton text="새로고침" onClick={refresh} iconProps={{ iconName: 'Refresh' }} />
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 16 }} className="filters-container">
                <DatePicker
                    label="시작 날짜"
                    value={startDate}
                    onSelectDate={handleDateChange}
                    formatDate={(date) => date.toLocaleDateString()}
                    className="date-picker"
                />

                <SearchBox
                    placeholder="일정 검색..."
                    onChange={(_, value) => setSearchQuery(value || '')}
                    className="search-box"
                />
            </Stack>

            <div className="events-count">
                <Text>총 {filteredEvents.length}개의 일정이 있습니다.</Text>
            </div>

            <div style={{ height: '600px', overflowY: 'auto', border: '1px solid #eee', padding: '10px' }}>
                {/* 기본 테이블 구조로 일정 목록 표시 */}
                {filteredEvents.length > 0 && (
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #ddd', backgroundColor: '#f5f5f5' }}>
                                <th style={{ padding: '10px', textAlign: 'left' }}>제목</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>시작 시간</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>종료 시간</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>장소</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>작업</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEvents.map(event => (
                                <tr key={event.id} style={{ borderBottom: '1px solid #eee' }}>
                                    <td style={{ padding: '10px' }}>{event.subject || '(제목 없음)'}</td>
                                    <td style={{ padding: '10px' }}>{new Date(event.start.dateTime).toLocaleString()}</td>
                                    <td style={{ padding: '10px' }}>{new Date(event.end.dateTime).toLocaleString()}</td>
                                    <td style={{ padding: '10px' }}>{event.location?.displayName || '(장소 없음)'}</td>
                                    <td style={{ padding: '10px' }}>
                                        <DefaultButton
                                            text="보기"
                                            onClick={() => handleViewEvent(event)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {/* DetailsList 컴포넌트 - 숨김 처리 */}
                <div style={{ display: 'none' }}>
                    <DetailsList
                        items={filteredEvents}
                        columns={columns}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        onRenderRow={(props) => {
                            const customStyles = {
                                root: {
                                    cursor: 'pointer',
                                    selectors: {
                                        '&:hover': {
                                            backgroundColor: '#f3f2f1'
                                        }
                                    }
                                }
                            };
                            return <div style={customStyles.root}>{props.children}</div>;
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CalendarPage; 
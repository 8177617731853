import React, { createContext, useState, useContext, useCallback } from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';
import './NotificationContext.css';

// 알림 컨텍스트 생성
const NotificationContext = createContext();

// 알림 타입 매핑
const messageBarTypes = {
  info: MessageBarType.info,
  success: MessageBarType.success,
  warning: MessageBarType.warning,
  error: MessageBarType.error,
  severeWarning: MessageBarType.severeWarning,
  blocked: MessageBarType.blocked
};

// 알림 프로바이더 컴포넌트
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  
  // 알림 표시 함수
  const showNotification = useCallback(({ message, type = 'info', duration = 5000 }) => {
    const id = Date.now(); // 고유 ID 생성
    
    // 새 알림 추가
    setNotifications(prev => [...prev, { id, message, type, duration }]);
    
    // 지정된 시간 후 알림 제거
    if (duration > 0) {
      setTimeout(() => {
        dismissNotification(id);
      }, duration);
    }
    
    return id; // 알림 ID 반환 (수동 제거 가능)
  }, []);
  
  // 알림 제거 함수
  const dismissNotification = useCallback((id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  }, []);
  
  // 모든 알림 제거 함수
  const clearAllNotifications = useCallback(() => {
    setNotifications([]);
  }, []);
  
  // 컨텍스트 값
  const value = {
    notifications,
    showNotification,
    dismissNotification,
    clearAllNotifications
  };
  
  return (
    <NotificationContext.Provider value={value}>
      {children}
      
      {/* 알림 렌더링 */}
      <div className="notifications-container">
        {notifications.map(notification => (
          <div key={notification.id} className="notification-item">
            <MessageBar
              messageBarType={messageBarTypes[notification.type] || MessageBarType.info}
              onDismiss={() => dismissNotification(notification.id)}
              dismissButtonAriaLabel="닫기"
              className="notification-message"
            >
              {notification.message}
            </MessageBar>
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

// 알림 컨텍스트 사용을 위한 훅
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export default NotificationContext; 
import React from 'react';
import {
  Stack,
  Text,
  Persona,
  PersonaSize,
  Separator,
  DefaultButton,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  Image,
  ImageFit,
  Label
} from '@fluentui/react';
import { useAuth } from '../../context/AuthContext';
import useGraphData from '../../hooks/useGraphData';
import './ProfilePage.css';

const ProfilePage = () => {
  const { userInfo, isLoading: authLoading, error: authError } = useAuth();
  const { data: photoData, loading: photoLoading, error: photoError } = useGraphData('/me/photo/$value');
  
  const isLoading = authLoading || photoLoading;
  const hasError = authError || photoError;
  
  if (isLoading) {
    return (
      <div className="loading-container">
        <Spinner size={SpinnerSize.large} label="프로필 정보를 불러오는 중..." />
      </div>
    );
  }
  
  if (hasError) {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        오류가 발생했습니다: {authError || photoError}
      </MessageBar>
    );
  }
  
  if (!userInfo) {
    return (
      <MessageBar messageBarType={MessageBarType.warning}>
        사용자 정보를 불러올 수 없습니다.
      </MessageBar>
    );
  }

  return (
    <div className="profile-page">
      <Text variant="xxLarge" className="page-title">내 프로필</Text>
      
      <div className="profile-container">
        <DocumentCard className="profile-card">
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className="profile-photo-container">
              <Persona
                imageUrl={photoData ? URL.createObjectURL(new Blob([photoData])) : undefined}
                text={userInfo.displayName}
                size={PersonaSize.size100}
                className="profile-photo"
              />
            </Stack.Item>
            
            <Stack.Item grow className="profile-info">
              <Text variant="xLarge">{userInfo.displayName}</Text>
              <Text variant="medium">{userInfo.jobTitle || '직책 정보 없음'}</Text>
              <Text variant="medium">{userInfo.mail || userInfo.userPrincipalName}</Text>
              <Text variant="medium">{userInfo.mobilePhone || '연락처 정보 없음'}</Text>
              <Text variant="medium">{userInfo.officeLocation || '근무지 정보 없음'}</Text>
            </Stack.Item>
          </Stack>
          
          <Separator className="section-separator" />
          
          <div className="profile-actions">
            <DefaultButton iconProps={{ iconName: 'EditContact' }}>프로필 업데이트</DefaultButton>
            <DefaultButton iconProps={{ iconName: 'ChangeEntitlements' }}>설정</DefaultButton>
          </div>
        </DocumentCard>
        
        <Stack className="profile-details" tokens={{ childrenGap: 16 }}>
          <DocumentCard className="details-card">
            <DocumentCardTitle title="부서 정보" />
            
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal horizontalAlign="space-between">
                <Label>부서</Label>
                <Text>{userInfo.department || '정보 없음'}</Text>
              </Stack>
              
              <Stack horizontal horizontalAlign="space-between">
                <Label>관리자</Label>
                <Text>{userInfo.manager || '정보 없음'}</Text>
              </Stack>
              
              <Stack horizontal horizontalAlign="space-between">
                <Label>회사</Label>
                <Text>{userInfo.companyName || '정보 없음'}</Text>
              </Stack>
            </Stack>
          </DocumentCard>
          
          <DocumentCard className="details-card">
            <DocumentCardTitle title="연락처 정보" />
            
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal horizontalAlign="space-between">
                <Label>이메일</Label>
                <Text>{userInfo.mail || userInfo.userPrincipalName}</Text>
              </Stack>
              
              <Stack horizontal horizontalAlign="space-between">
                <Label>사무실 전화</Label>
                <Text>{userInfo.businessPhones && userInfo.businessPhones.length > 0 ? userInfo.businessPhones[0] : '정보 없음'}</Text>
              </Stack>
              
              <Stack horizontal horizontalAlign="space-between">
                <Label>휴대폰</Label>
                <Text>{userInfo.mobilePhone || '정보 없음'}</Text>
              </Stack>
            </Stack>
          </DocumentCard>
          
          <DocumentCard className="details-card">
            <DocumentCardTitle title="계정 정보" />
            
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal horizontalAlign="space-between">
                <Label>사용자 ID</Label>
                <Text>{userInfo.id || '정보 없음'}</Text>
              </Stack>
              
              <Stack horizontal horizontalAlign="space-between">
                <Label>사용자 계정</Label>
                <Text>{userInfo.userPrincipalName || '정보 없음'}</Text>
              </Stack>
            </Stack>
          </DocumentCard>
        </Stack>
      </div>
    </div>
  );
};

export default ProfilePage; 
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page {
  padding: 20px;
}

.welcome-section {
  margin-bottom: 30px;
}

.section-title {
  margin-bottom: 16px;
  font-weight: 600;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.file-card,
.event-card,
.email-card {
  max-width: 100%;
  height: 120px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.file-card:hover,
.event-card:hover,
.email-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
} `, "",{"version":3,"sources":["webpack://./src/pages/Home/HomePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;;;EAGE,eAAe;EACf,aAAa;EACb,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;;;EAGE,2BAA2B;EAC3B,yCAAyC;EACzC,eAAe;AACjB","sourcesContent":[".home-page {\r\n  padding: 20px;\r\n}\r\n\r\n.welcome-section {\r\n  margin-bottom: 30px;\r\n}\r\n\r\n.section-title {\r\n  margin-bottom: 16px;\r\n  font-weight: 600;\r\n}\r\n\r\n.card-grid {\r\n  display: grid;\r\n  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));\r\n  gap: 16px;\r\n}\r\n\r\n.loading-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 400px;\r\n}\r\n\r\n.file-card,\r\n.event-card,\r\n.email-card {\r\n  max-width: 100%;\r\n  height: 120px;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  transition: transform 0.2s, box-shadow 0.2s;\r\n}\r\n\r\n.file-card:hover,\r\n.event-card:hover,\r\n.email-card:hover {\r\n  transform: translateY(-2px);\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);\r\n  cursor: pointer;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Nav } from '@fluentui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import './LeftNavigation.css';

const LeftNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const navLinks = [
        {
            name: '홈',
            url: '/',
            key: 'home',
            icon: 'Home'
        },
        {
            name: '프로필',
            url: '/profile',
            key: 'profile',
            icon: 'Contact'
        },
        {
            name: '이메일',
            url: '/emails',
            key: 'emails',
            icon: 'Mail'
        },
        {
            name: '채팅',
            url: '/chats',
            key: 'chats',
            icon: 'Chat'
        },
        {
            name: '캘린더',
            url: '/calendar',
            key: 'calendar',
            icon: 'Calendar'
        },
        {
            name: '사용자',
            url: '/users',
            key: 'users',
            icon: 'People'
        },
        {
            name: '팀',
            url: '/teams',
            key: 'teams',
            icon: 'Group'
        },
        {
            name: '통화 기록',
            url: '/callrecords',
            key: 'callrecords',
            icon: 'Phone'
        },
        {
            name: '할 일',
            url: '/todo',
            key: 'todo',
            icon: 'CheckList'
        }
    ];

    const handleLinkClick = (ev, item) => {
        ev.preventDefault();
        navigate(item.url);
    };

    return (
        <div className="left-navigation">
            <Nav
                groups={[
                    {
                        links: navLinks.map(link => ({
                            ...link,
                            onClick: handleLinkClick,
                            isActive: location.pathname === link.url
                        }))
                    }
                ]}
                selectedKey={navLinks.find(link => link.url === location.pathname)?.key || 'home'}
                styles={{
                    root: {
                        width: '100%',
                        height: '100%',
                        boxSizing: 'border-box',
                    }
                }}
            />
        </div>
    );
};

export default LeftNavigation; 
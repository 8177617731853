// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call-records-page {
  padding: 20px;
  height: 100%;
}

.page-header {
  margin-bottom: 16px;
}

.search-box {
  margin-bottom: 16px;
  width: 300px;
}

.call-records-count {
  margin-bottom: 16px;
  color: #666;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.call-type-cell, .call-direction-cell {
  display: flex;
  align-items: center;
}

.call-type-icon, .call-direction-icon {
  margin-right: 8px;
}

.call-duration {
  font-family: monospace;
}

.action-cell {
  display: flex;
  gap: 8px;
}

.refresh-button {
  margin-left: 16px;
} `, "",{"version":3,"sources":["webpack://./src/pages/CallRecords/CallRecordsPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".call-records-page {\r\n  padding: 20px;\r\n  height: 100%;\r\n}\r\n\r\n.page-header {\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.search-box {\r\n  margin-bottom: 16px;\r\n  width: 300px;\r\n}\r\n\r\n.call-records-count {\r\n  margin-bottom: 16px;\r\n  color: #666;\r\n}\r\n\r\n.loading-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 300px;\r\n}\r\n\r\n.call-type-cell, .call-direction-cell {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.call-type-icon, .call-direction-icon {\r\n  margin-right: 8px;\r\n}\r\n\r\n.call-duration {\r\n  font-family: monospace;\r\n}\r\n\r\n.action-cell {\r\n  display: flex;\r\n  gap: 8px;\r\n}\r\n\r\n.refresh-button {\r\n  margin-left: 16px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

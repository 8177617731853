// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-navigation {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-title {
  padding: 16px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
} `, "",{"version":3,"sources":["webpack://./src/components/layout/LeftNavigation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gCAAgC;AAClC","sourcesContent":[".left-navigation {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100%;\r\n}\r\n\r\n.app-title {\r\n  padding: 16px;\r\n  font-size: 18px;\r\n  font-weight: 600;\r\n  text-align: center;\r\n  border-bottom: 1px solid #e6e6e6;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

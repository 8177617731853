import React from 'react';
import { Text, Stack, Separator, PersonaSize, Persona, ActionButton } from '@fluentui/react';
import './EmailDetail.css';

const EmailDetail = ({ email }) => {
  if (!email) {
    return <Text>이메일을 찾을 수 없습니다.</Text>;
  }

  // HTML 컨텐츠를 안전하게 표시
  const createMarkup = (html) => {
    return { __html: html || '<p>내용 없음</p>' };
  };

  return (
    <div className="email-detail">
      <div className="email-header">
        <Text variant="xLarge" className="email-subject">
          {email.subject || '(제목 없음)'}
        </Text>
        
        <div className="email-metadata">
          <Persona
            text={email.from?.emailAddress?.name || '알 수 없음'}
            secondaryText={email.from?.emailAddress?.address || ''}
            size={PersonaSize.size32}
          />
          
          <Text className="email-date">
            {new Date(email.receivedDateTime).toLocaleString()}
          </Text>
        </div>
        
        {email.toRecipients && email.toRecipients.length > 0 && (
          <div className="email-recipients">
            <Text>받는 사람: </Text>
            {email.toRecipients.map((recipient, index) => (
              <Text key={index}>
                {recipient.emailAddress.name || recipient.emailAddress.address}
                {index < email.toRecipients.length - 1 ? ', ' : ''}
              </Text>
            ))}
          </div>
        )}
      </div>

      <Separator />
      
      <div className="email-body">
        {email.bodyPreview && !email.body?.content ? (
          <Text>{email.bodyPreview}</Text>
        ) : (
          <div dangerouslySetInnerHTML={createMarkup(email.body?.content)} />
        )}
      </div>

      {email.hasAttachments && (
        <div className="email-attachments">
          <Separator />
          <Text variant="mediumPlus" className="attachments-title">
            첨부 파일
          </Text>
          <Text>첨부 파일이 있지만 세부 정보는 제공되지 않았습니다.</Text>
        </div>
      )}

      <Separator />
      
      <Stack horizontal tokens={{ childrenGap: 10 }} className="email-actions">
        <ActionButton iconProps={{ iconName: 'Reply' }}>답장</ActionButton>
        <ActionButton iconProps={{ iconName: 'Forward' }}>전달</ActionButton>
        <ActionButton iconProps={{ iconName: 'Delete' }}>삭제</ActionButton>
      </Stack>
    </div>
  );
};

export default EmailDetail; 
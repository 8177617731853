import React from 'react';
import {
  Stack,
  Text,
  Separator,
  Persona,
  PersonaSize,
  ActionButton,
  FontIcon,
  Label,
  Link
} from '@fluentui/react';
import './CalendarEventDetail.css';

const CalendarEventDetail = ({ event }) => {
  if (!event) {
    return <Text>일정 정보를 찾을 수 없습니다.</Text>;
  }

  // HTML 컨텐츠를 안전하게 표시
  const createMarkup = (html) => {
    return { __html: html || '<p>내용 없음</p>' };
  };

  // 날짜 포맷팅
  const formatDateTimeRange = (startDateTime, endDateTime) => {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);
    
    // 시간 범위 표시
    const timeFormat = { hour: '2-digit', minute: '2-digit' };
    const startTime = start.toLocaleTimeString([], timeFormat);
    const endTime = end.toLocaleTimeString([], timeFormat);
    
    // 날짜 표시
    const dateFormat = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' };
    const dateStr = start.toLocaleDateString([], dateFormat);
    
    return `${dateStr} ${startTime} - ${endTime}`;
  };

  // 이벤트 참석자 목록
  const renderAttendees = () => {
    if (!event.attendees || event.attendees.length === 0) {
      return <Text>참석자가 없습니다.</Text>;
    }

    return (
      <div className="attendees-list">
        {event.attendees.map((attendee, index) => (
          <Persona
            key={index}
            text={attendee.emailAddress?.name || attendee.emailAddress?.address || '알 수 없음'}
            secondaryText={attendee.type || ''}
            size={PersonaSize.size32}
            className="attendee-item"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="event-detail">
      <div className="event-header">
        <Text variant="xLarge" className="event-subject">
          {event.subject || '(제목 없음)'}
        </Text>
      </div>

      <Separator />
      
      <Stack tokens={{ childrenGap: 16 }} className="event-info">
        {/* 시간 정보 */}
        <Stack horizontal verticalAlign="center" className="info-item">
          <FontIcon iconName="Clock" className="info-icon" />
          <div>
            <Text className="info-label">시간</Text>
            <Text>{formatDateTimeRange(event.start.dateTime, event.end.dateTime)}</Text>
          </div>
        </Stack>
        
        {/* 장소 정보 */}
        {event.location && event.location.displayName && (
          <Stack horizontal verticalAlign="center" className="info-item">
            <FontIcon iconName="MapPin" className="info-icon" />
            <div>
              <Text className="info-label">장소</Text>
              <Text>{event.location.displayName}</Text>
            </div>
          </Stack>
        )}
        
        {/* 온라인 회의 정보 */}
        {event.onlineMeeting && event.onlineMeeting.joinUrl && (
          <Stack horizontal verticalAlign="center" className="info-item">
            <FontIcon iconName="Video" className="info-icon" />
            <div>
              <Text className="info-label">온라인 회의</Text>
              <Link href={event.onlineMeeting.joinUrl} target="_blank" className="meeting-link">
                회의 참가
              </Link>
            </div>
          </Stack>
        )}
        
        {/* 주최자 정보 */}
        <Stack horizontal verticalAlign="center" className="info-item">
          <FontIcon iconName="Contact" className="info-icon" />
          <div>
            <Text className="info-label">주최자</Text>
            <Persona
              text={event.organizer?.emailAddress?.name || '알 수 없음'}
              secondaryText={event.organizer?.emailAddress?.address || ''}
              size={PersonaSize.size24}
            />
          </div>
        </Stack>
      </Stack>

      <Separator />
      
      {/* 참석자 정보 */}
      <div className="event-attendees">
        <Text variant="mediumPlus" className="section-title">참석자</Text>
        {renderAttendees()}
      </div>

      <Separator />
      
      {/* 일정 내용 */}
      <div className="event-body">
        <Text variant="mediumPlus" className="section-title">상세 내용</Text>
        {event.body?.contentType === 'html' ? (
          <div className="html-content" dangerouslySetInnerHTML={createMarkup(event.body.content)} />
        ) : (
          <Text>{event.bodyPreview || event.body?.content || '(내용 없음)'}</Text>
        )}
      </div>

      <Separator />
      
      <Stack horizontal tokens={{ childrenGap: 10 }} className="event-actions">
        <ActionButton iconProps={{ iconName: 'Accept' }}>참석</ActionButton>
        <ActionButton iconProps={{ iconName: 'Decline' }}>불참</ActionButton>
        <ActionButton iconProps={{ iconName: 'Tentative' }}>미정</ActionButton>
      </Stack>
    </div>
  );
};

export default CalendarEventDetail; 
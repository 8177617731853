import React, { useState, useEffect } from 'react';
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    Text,
    SearchBox,
    Stack,
    DefaultButton,
    Persona,
    PersonaSize,
    IconButton
} from '@fluentui/react';
import { useOutletContext } from 'react-router-dom';
import useGraphData from '../../hooks/useGraphData';
import UserDetail from './UserDetail';
import { useNotification } from '../../context/NotificationContext';
import './UsersPage.css';

const UsersPage = () => {
    const { data: users, loading, error, refresh } = useGraphData('/users?$select=id,displayName,userPrincipalName,mail,jobTitle,department,mobilePhone');
    const [searchQuery, setSearchQuery] = useState('');
    const { openDetailPanel } = useOutletContext();
    const { showNotification } = useNotification();

    // 테스트용 목데이터 추가
    const mockUsers = [
        {
            id: '1',
            displayName: '홍길동',
            userPrincipalName: 'hong@example.com',
            mail: 'hong@example.com',
            jobTitle: '개발자',
            department: 'IT 부서',
            mobilePhone: '010-1234-5678'
        },
        {
            id: '2',
            displayName: '김영희',
            userPrincipalName: 'kim@example.com',
            mail: 'kim@example.com',
            jobTitle: '디자이너',
            department: '디자인 부서',
            mobilePhone: '010-2345-6789'
        }
    ];

    // 실제 API 데이터 대신 목데이터 사용 (디버깅용)
    const allUsers = users.length > 0 ? users : mockUsers;

    useEffect(() => {
        console.log('API에서 받은 사용자 데이터:', users);
        console.log('현재 사용 중인 데이터:', allUsers);
    }, [users, allUsers]);

    // 사용자 필터링
    const filteredUsers = allUsers.filter(user =>
        user.displayName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.userPrincipalName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.mail?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.jobTitle?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.department?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // 사용자 상세 보기
    const handleViewUser = (user) => {
        openDetailPanel(`사용자: ${user.displayName || '이름 없음'}`, <UserDetail userId={user.id} />);
    };

    // 사용자에게 전화 걸기
    const handleCallUser = (user) => {
        // Teams Deep Link를 통해 전화 걸기
        const userPrincipalName = user.userPrincipalName;
        if (userPrincipalName) {
            const deepLink = `https://teams.microsoft.com/l/call/0/0?users=${userPrincipalName}`;
            window.open(deepLink, '_blank');
            showNotification({
                message: `${user.displayName || '사용자'}에게 전화 연결 중...`,
                type: 'success'
            });
        } else {
            showNotification({
                message: '전화를 걸 수 없습니다. 사용자 정보가 부족합니다.',
                type: 'error'
            });
        }
    };

    // 사용자와 미팅 시작하기
    const handleMeetUser = (user) => {
        // Teams Deep Link를 통해 미팅 시작
        const userPrincipalName = user.userPrincipalName;
        if (userPrincipalName) {
            const deepLink = `https://teams.microsoft.com/l/meeting/new?attendees=${userPrincipalName}`;
            window.open(deepLink, '_blank');
            showNotification({
                message: `${user.displayName || '사용자'}와의 미팅을 설정 중...`,
                type: 'success'
            });
        } else {
            showNotification({
                message: '미팅을 설정할 수 없습니다. 사용자 정보가 부족합니다.',
                type: 'error'
            });
        }
    };

    // 사용자 목록 컬럼 정의 (DetailsList용 - 현재 사용하지 않음)
    const columns = [
        {
            key: 'displayName',
            name: '이름',
            fieldName: 'displayName',
            minWidth: 150,
            maxWidth: 200,
            onRender: (item) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleViewUser(item)}
                >
                    <Persona
                        text={item.displayName || '(이름 없음)'}
                        secondaryText={item.jobTitle || ''}
                        size={PersonaSize.size32}
                    />
                </div>
            )
        },
        {
            key: 'mail',
            name: '이메일',
            fieldName: 'mail',
            minWidth: 200,
            onRender: (item) => item.mail || item.userPrincipalName || '(이메일 없음)'
        },
        {
            key: 'department',
            name: '부서',
            fieldName: 'department',
            minWidth: 150,
            onRender: (item) => item.department || '(부서 정보 없음)'
        },
        {
            key: 'mobilePhone',
            name: '연락처',
            fieldName: 'mobilePhone',
            minWidth: 120,
            onRender: (item) => item.mobilePhone || '(연락처 정보 없음)'
        },
        {
            key: 'actions',
            name: '작업',
            minWidth: 120,
            onRender: (item) => (
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <IconButton
                        iconProps={{ iconName: 'Phone' }}
                        title="전화 걸기"
                        onClick={() => handleCallUser(item)}
                    />
                    <IconButton
                        iconProps={{ iconName: 'TeamsLogo' }}
                        title="미팅 하기"
                        onClick={() => handleMeetUser(item)}
                    />
                </Stack>
            )
        }
    ];

    if (loading) {
        return (
            <div className="loading-container">
                <Spinner size={SpinnerSize.large} label="사용자 목록을 불러오는 중..." />
            </div>
        );
    }

    if (error) {
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                오류가 발생했습니다: {error}
            </MessageBar>
        );
    }

    return (
        <div className="users-page">
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="page-header">
                <Text variant="xxLarge">사용자</Text>
                <DefaultButton text="새로고침" onClick={refresh} iconProps={{ iconName: 'Refresh' }} />
            </Stack>

            <SearchBox
                placeholder="사용자 검색..."
                onChange={(_, value) => setSearchQuery(value || '')}
                className="search-box"
            />

            <div className="users-count">
                <Text>총 {filteredUsers.length}명의 사용자가 있습니다.</Text>
                {console.log('필터링된 사용자 목록:', filteredUsers)}
            </div>

            <div style={{ height: '600px', overflowY: 'auto', border: '1px solid #eee', padding: '10px' }}>
                {/* 기본 테이블 구조로 사용자 목록 표시 */}
                {filteredUsers.length > 0 && (
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #ddd', backgroundColor: '#f5f5f5' }}>
                                <th style={{ padding: '10px', textAlign: 'left' }}>이름</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>이메일</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>부서</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>연락처</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>작업</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map(user => (
                                <tr key={user.id} style={{ borderBottom: '1px solid #eee' }}>
                                    <td style={{ padding: '10px', cursor: 'pointer' }} onClick={() => handleViewUser(user)}>
                                        <Persona
                                            text={user.displayName || '(이름 없음)'}
                                            secondaryText={user.jobTitle || ''}
                                            size={PersonaSize.size32}
                                        />
                                    </td>
                                    <td style={{ padding: '10px' }}>{user.mail || user.userPrincipalName || '(이메일 없음)'}</td>
                                    <td style={{ padding: '10px' }}>{user.department || '(부서 정보 없음)'}</td>
                                    <td style={{ padding: '10px' }}>{user.mobilePhone || '(연락처 정보 없음)'}</td>
                                    <td style={{ padding: '10px' }}>
                                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                                            <IconButton
                                                iconProps={{ iconName: 'Phone' }}
                                                title="전화 걸기"
                                                onClick={() => handleCallUser(user)}
                                            />
                                            <IconButton
                                                iconProps={{ iconName: 'TeamsLogo' }}
                                                title="미팅 하기"
                                                onClick={() => handleMeetUser(user)}
                                            />
                                        </Stack>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {/* DetailsList 컴포넌트 - 숨김 처리 */}
                <div style={{ display: 'none' }}>
                    {filteredUsers.length > 0 && (
                        <DetailsList
                            items={filteredUsers}
                            columns={columns}
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            isHeaderVisible={true}
                            compact={false}
                            onRenderRow={(props) => {
                                const customStyles = {
                                    root: {
                                        cursor: 'default',
                                        selectors: {
                                            '&:hover': {
                                                backgroundColor: '#f3f2f1'
                                            }
                                        }
                                    }
                                };
                                return <div style={customStyles.root}>{props.children}</div>;
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default UsersPage; 
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-layout-container {
  height: 100vh;
  overflow: hidden;
}

.left-nav-container {
  width: 250px;
  height: 100%;
  border-right: 1px solid #e6e6e6;
  background-color: #f9f9f9;
}

.main-content-container {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
}

/* Panel은 Fluent UI의 내장 스타일을 사용합니다 */ `, "",{"version":3,"sources":["webpack://./src/components/layout/AppLayout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;AACf;;AAEA,oCAAoC","sourcesContent":[".app-layout-container {\r\n  height: 100vh;\r\n  overflow: hidden;\r\n}\r\n\r\n.left-nav-container {\r\n  width: 250px;\r\n  height: 100%;\r\n  border-right: 1px solid #e6e6e6;\r\n  background-color: #f9f9f9;\r\n}\r\n\r\n.main-content-container {\r\n  height: 100%;\r\n  overflow-y: auto;\r\n  padding: 20px;\r\n}\r\n\r\n/* Panel은 Fluent UI의 내장 스타일을 사용합니다 */ "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

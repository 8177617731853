import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { useAuth } from './context/AuthContext';
import AppLayout from './components/layout/AppLayout';
import HomePage from './pages/Home/HomePage';
import EmailsPage from './pages/Emails/EmailsPage';
import ProfilePage from './pages/Profile/ProfilePage';
import ChatsPage from './pages/Chats/ChatsPage';
import CalendarPage from './pages/Calendar/CalendarPage';
import UsersPage from './pages/Users/UsersPage';
import TeamsPage from './pages/Teams/TeamsPage';
import CallRecordsPage from './pages/CallRecords/CallRecordsPage';
import Todo from './components/Todo';
import { NotificationProvider } from './context/NotificationContext';
import './App.css';

// Fluent UI 아이콘 초기화
initializeIcons();

// 로딩 컴포넌트
const LoadingPage = () => (
  <div className="loading-page">
    <div className="loading-spinner"></div>
    <p>로딩 중...</p>
  </div>
);

// 에러 컴포넌트
const ErrorPage = ({ message }) => (
  <div className="error-page">
    <h2>오류가 발생했습니다</h2>
    <p>{message}</p>
  </div>
);

const App = () => {
  const { isAuthenticated, isLoading, error } = useAuth();

  // 로딩 중일 때 로딩 UI 표시
  if (isLoading) {
    return <LoadingPage />;
  }

  // 오류 발생 시 오류 페이지 표시
  if (error) {
    return <ErrorPage message={error} />;
  }

  return (
    <NotificationProvider>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<HomePage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="emails" element={<EmailsPage />} />
          <Route path="chats" element={<ChatsPage />} />
          <Route path="calendar" element={<CalendarPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="teams" element={<TeamsPage />} />
          <Route path="callrecords" element={<CallRecordsPage />} />
          <Route path="todo" element={<Todo />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </NotificationProvider>
  );
};

export default App;
import React, { useEffect, useState, useCallback } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import './App.css'; // 스타일링 파일

const App = () => {
    const [graphToken, setGraphToken] = useState(null); // Graph API 토큰 상태
    const [userInfo, setUserInfo] = useState(null); // 사용자 정보
    const [users, setUsers] = useState([]); ; // 사용자 리스트
    const [chatMessages, setChatMessages] = useState([]); // 채팅방 목록
    const [emails, setEmails] = useState([]); // 이메일 목록
    const [calendarEvents, setCalendarEvents] = useState([]); // 캘린더 이벤트
    // const [channels, setChannels] = useState([]); // 채널 목록
    const [teams, setTeams] = useState([]); // 팀 목록
    const [callRecords, setCallRecords] = useState([]); // 통화 기록
    const [selectedChatMessages, setSelectedChatMessages] = useState([]); // 선택된 채팅방 메시지
    const [selectedChatTopic, setSelectedChatTopic] = useState(''); // 선택된 채팅방 제목
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [recentFiles, setRecentFiles] = useState([]);
    const [callHistory, setCallHistory] = useState([]);

    useEffect(() => {
        // Teams 인증 및 초기화
        microsoftTeams.app.initialize()
            .then(() => {
                microsoftTeams.authentication.getAuthToken({
                    successCallback: (token) => {
                        console.log('Teams Token:', token);
                        // 서버로 Teams 토큰을 보내고 Graph API 토큰을 받아옴
                        fetchGraphToken(token);
                    },
                    failureCallback: (error) => {
                        console.error("Failed to get token", error);
                        setError("Failed to get authentication token");
                    }
                });
            });
    }, []);

    // 서버로 Teams 토큰을 보내고 Graph API용 토큰을 받는 함수
    const fetchGraphToken = useCallback((teamsToken) => {
        fetch('https://spo.in-bridge.net/api/get-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: teamsToken })
        })
            .then(response => response.json())
            .then(data => {
                if (data.accessToken) {
                    console.log('Graph API Access Token:', data.accessToken);
                    setGraphToken(data.accessToken); // 전역적으로 사용할 수 있게 토큰을 저장
                } else {
                    setError('Failed to get Graph API token from the server');
                }
            })
            .catch(error => {
                console.error('Error fetching Graph API token:', error);
                setError('Error fetching Graph API token');
            });
    }, []);

    // 토큰이 설정된 이후에 API 요청들 실행
    useEffect(() => {
        if (graphToken) {
            fetchUserInfo(graphToken);
            fetchUsers(graphToken);
            fetchChatMessages(graphToken);
            fetchEmails(graphToken);
            fetchCalendar(graphToken);
            // fetchCallRecords('/api/callrecords'); // 통화 기록 가져오기
            fetchTeamsAndChannels(graphToken); // 팀과 채널 가져오기
            fetchContacts(graphToken);
            fetchRecentFiles(graphToken);
            // fetchCallHistory();
        }
    }, [graphToken]);

    // 올메세지 
    const fetchCallHistory = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch('/api/callhistory');  // 서버 API 호출
            if (!response.ok) {
                throw new Error('통화 기록을 불러오는데 실패했습니다.');
            }
            const data = await response.json();
            console.log(data)
            setCallHistory(data || []);  // 통화 기록 목록 설정
        } catch (error) {
            console.error('Failed to fetch call records:', error);
            setError('통화 기록을 가져오는 중 오류가 발생했습니다.');
        } finally {
            setLoading(false);
        }
    }, []);

    // 사용자 정보 가져오기
    const fetchUserInfo = useCallback((token) => {
        fetch('https://graph.microsoft.com/v1.0/me', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => setUserInfo(data))
            .catch(error => setError("Failed to fetch user info"));
    }, []);

    //사용자 리스트 
    const fetchUsers = useCallback((token) => {
        fetch('https://graph.microsoft.com/v1.0/users?$select=id,displayName,userPrincipalName', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => setUsers(data.value || [])) // 사용자 목록을 상태에 저장
            .catch(error => {
                console.error('Failed to fetch users:', error);
                setError("Failed to fetch users");
            });
    }, []);

    // Teams Deep Link를 통해 전화를 걸기 위한 함수
    const handleCallClickBlank = (userPrincipalName) => {
        const deepLink = `https://teams.microsoft.com/l/call/0/0?users=${userPrincipalName}`;
        window.open(deepLink, '_blank'); // 새 탭에서 Teams 통화 창 열기
    };

    const handleCallClick = (userPrincipalName) => {
        const deepLink = `https://teams.microsoft.com/l/call/0/0?users=${userPrincipalName}`;

        microsoftTeams.executeDeepLink(deepLink); // Teams SDK를 통해 Deep Link 실행
    };

    // 채팅방 목록 가져오기
    const fetchChatMessages = useCallback((token) => {
        fetch('https://graph.microsoft.com/v1.0/me/chats', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => setChatMessages(data.value || [])) // null/undefined 방지를 위해 기본값으로 빈 배열
            .catch(error => setError("Failed to fetch chat messages"));
    }, []);

    // 이메일 목록 가져오기
    const fetchEmails = useCallback((token) => {
        fetch('https://graph.microsoft.com/v1.0/me/messages', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => setEmails(data.value || [])) // null/undefined 방지를 위해 기본값으로 빈 배열
            .catch(error => setError("Failed to fetch emails"));
    }, []);

    // 캘린더 이벤트 목록 가져오기
    const fetchCalendar = useCallback((token) => {
        fetch('https://graph.microsoft.com/v1.0/me/calendar/events', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => setCalendarEvents(data.value || [])) // null/undefined 방지를 위해 기본값으로 빈 배열
            .catch(error => setError("Failed to fetch calendar events"));
    }, []);

    // 팀 및 채널 목록 가져오기 (통합)
    const fetchTeamsAndChannels = useCallback((token) => {
        fetch('https://graph.microsoft.com/v1.0/me/joinedTeams', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setTeams(data.value || []); // 팀 목록 설정
                // setChannels(data.value || []); // 채널 목록 설정
            })
            .catch(error => setError("Failed to fetch teams and channels"));
    }, []);

    // 통화 기록 가져오기
    const fetchCallRecords = useCallback(async (apiEndpoint) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(apiEndpoint);  // 서버 API 호출
            if (!response.ok) {
                throw new Error('통화 기록을 불러오는데 실패했습니다.');
            }
            const data = await response.json();
            setCallRecords(data.value || []);  // 통화 기록 목록 설정
        } catch (error) {
            console.error('Failed to fetch call records:', error);
            setError('통화 기록을 가져오는 중 오류가 발생했습니다.');
        } finally {
            setLoading(false);
        }
    }, []);

    // 특정 채팅방의 메시지 가져오기
    const fetchMessagesFromChat = (chatId, chatTopic, token) => {
        fetch(`https://graph.microsoft.com/v1.0/chats/${chatId}/messages`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setSelectedChatMessages(data.value || []); // 선택된 채팅방 메시지 저장
                setSelectedChatTopic(chatTopic); // 선택된 채팅방 제목 저장
                setIsModalOpen(true); // 모달 열기
            })
            .catch(error => setError("Failed to fetch chat messages"));
    };

    const fetchContacts = useCallback((token) => {
        fetch('https://graph.microsoft.com/v1.0/me/contacts', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Contacts:', data.value);
                setContacts(data.value || []);  // 연락처 데이터를 상태에 저장
            })
            .catch(error => setError("Failed to fetch contacts"));
    }, []);

    const fetchRecentFiles = useCallback((token) => {
        fetch('https://graph.microsoft.com/v1.0/me/drive/root/children', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Recent Files:', data.value);
                setRecentFiles(data.value || []);  // 최근 파일 데이터를 상태에 저장
            })
            .catch(error => setError("Failed to fetch recent files"));
    }, []);

    // 모달 닫기 함수
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // 미탕에 사용자 초대 
    const addAttendeeToMeeting = useCallback((meetingId, attendeeUserId,  userPrincipalName, joinUrl) => {
        if (!graphToken) {
            setError("Graph API 토큰이 없습니다.");
            return;
        }

        const attendee = {
            "participants": {
                "attendees": [
                    {
                        "identity": {
                            "user": {
                                "id": attendeeUserId
                            }
                        },
                        "role": "attendee"
                    }
                ]
            }
        };

        // Graph API 요청 (미팅에 참석자 추가)
        fetch(`https://graph.microsoft.com/v1.0/me/onlineMeetings/${meetingId}`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${graphToken}`,  // Bearer Token 포함
                'Content-Type': 'application/json'        // JSON 요청임을 명시
            },
            body: JSON.stringify(attendee)  // 참석자를 JSON으로 직렬화하여 보냄
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setError(`Failed to add attendee: ${data.error.message}`);
                } else {
                    console.log(`Attendee ${userPrincipalName} added successfully.`);
                    microsoftTeams.executeDeepLink(data.joinUrl)
                }
            })
            .catch(error => {
                console.error('Error adding attendee to meeting:', error);
                setError('참석자를 추가하는 중 오류가 발생했습니다.');
            });
    }, [graphToken]);


    // 미팅 생성 함수
    const createMeeting = useCallback((userId, userPrincipalName) => {
        if (!graphToken) {
            setError("Graph API 토큰이 없습니다.");
            return;
        }

        const meetingRequest = {
            subject: "Teams 오디오 미팅 2",
            startDateTime: new Date().toISOString(),
            endDateTime: new Date(new Date().getTime() + 30 * 60000).toISOString()  // 미팅 종료 시간 (현재 시간으로부터 30분 후)
        };

        fetch('https://graph.microsoft.com/v1.0/me/onlineMeetings', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${graphToken}`,  // Graph API 토큰
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(meetingRequest)
        })
            .then(response => response.json())
            .then(data => {
                if (data.joinUrl) {
                    console.log('Meeting created:', data.joinUrl);
                    // alert(`미팅이 생성되었습니다. 참가 링크: ${data.joinUrl}`);  // 미팅 참가 링크를 알림으로 표시
                    const meetingId = data.id;  // 생성된 미팅의 ID 가져오기
                    console.log('Meeting created:', data.joinUrl);
                    // alert(`미팅이 생성되었습니다. 참가 링크: ${data.joinUrl}`);

                    // 미팅 생성 후 사용자 초대 (점진적으로 추가)
                    addAttendeeToMeeting(meetingId, userId,  userPrincipalName, data.joinUrl);
                    
                } else {
                    setError('미팅 생성 실패');
                }
            })
            .catch(error => {
                console.error('Error creating the meeting:', error);
                setError('미팅 생성 중 오류가 발생했습니다.');
            });
    }, [graphToken]);


    return (
        <div className="dashboard">
            <h1>Teams Dashboard</h1>
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {userInfo && (
                <div className="card">
                    <h2>Profile</h2>
                    <p><strong>Name:</strong> {userInfo.displayName}</p>
                    <p><strong>Email:</strong> {userInfo.mail || userInfo.userPrincipalName}</p>
                    <p><strong>Job Title:</strong> {userInfo.jobTitle || 'N/A'}</p>
                </div>
            )}

            <div className="card">
                <h2>Emails</h2>
                {emails.length > 0 ? (
                    <ul>
                        {emails.slice(0, 5).map(email => (
                            <li key={email.id}>{email.subject}</li>
                        ))}
                    </ul>
                ) : <p>No recent emails.</p>}
            </div>

            <div className="card">
                <h2>Calendar Events</h2>
                {calendarEvents.length > 0 ? (
                    <ul>
                        {calendarEvents.slice(0, 5).map(event => (
                            <li key={event.id}>{event.subject}</li>
                        ))}
                    </ul>
                ) : <p>No upcoming events.</p>}
            </div>

            <div className="card">
                <h2>Mail Contacts</h2>
                {contacts.length > 0 ? (
                    <ul>
                        {contacts.map(contact => (
                            <li key={contact.id}>{contact.displayName}</li>
                        ))}
                    </ul>
                ) : <p>No contacts available.</p>}
            </div>

            <div className="card">
                <h2>Users List</h2>
                {users.length > 0 ? (
                    <ul>
                        {users.map(user => (
                            <li key={user.id}>{user.displayName} ({user.mail || 'No email available'})</li>
                        ))}
                    </ul>
                ) : <p>No users found.</p>}
            </div>

            <div className="card">
                <h2>Users List</h2>
                {users.length > 0 ? (
                    <ul>
                        {users.map(user => (
                            <li key={user.id}> {/* 여기에서 `key` prop을 설정합니다 */}
                                <p><strong>Name:</strong> {user.displayName}</p>
                                <p><strong>Email (UPN):</strong> {user.userPrincipalName}</p>
                                {/* 전화 걸기 버튼 추가 */}
                                <button onClick={() => handleCallClick(user.userPrincipalName)}>
                                    Call in Teams
                                </button>

                                {/* 오디오 미팅 생성 버튼 추가 */}
                                <button onClick={() => createMeeting(user.id, user.userPrincipalName)}>
                                    Create Audio Meeting
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : <p>No users found.</p>}
            </div>

            <div className="card">
                <h2>Recent Files</h2>
                {recentFiles.length > 0 ? (
                    <ul>
                        {recentFiles.map(file => (
                            <li key={file.id}>{file.name}</li>
                        ))}
                    </ul>
                ) : <p>No recent files available.</p>}
            </div>

            <div className="card">
                <h2>Chat 채널</h2>
                {chatMessages.length > 0 ? (
                    <ul>
                        {chatMessages.map(chat => (
                            <li key={chat.id} onClick={() => fetchMessagesFromChat(chat.id, chat.topic || 'No topic available', graphToken)}>
                                {chat.topic || 'No topic available'}
                            </li>
                        ))}
                    </ul>
                ) : <p>No recent chat messages.</p>}
            </div>

            <div className="card">
                <h2>Teams</h2>
                {teams.length > 0 ? (
                    <ul>
                        {teams.map(team => (
                            <li key={team.id}>{team.displayName}</li>
                        ))}
                    </ul>
                ) : <p>No joined teams.</p>}
            </div>

            <div className="card">
                <h2>Call History</h2>
                {callHistory.length > 0 ? (
                    <ul>
                        {callHistory.slice(0, 5).map(call => (
                            <li key={call.id} dangerouslySetInnerHTML={{ __html: call.body.content || 'No content' }} />
                        ))}
                    </ul>
                ) : <p>No recent call history.</p>}
            </div>

            <div className="card">
                <h2>통화 기록 목록</h2>
                {callRecords.length > 0 ? (
                    <ul>
                        {callRecords.map(record => (
                            <li key={record.id}>
                                <p><strong>참여자:</strong> {record.organizer?.user?.displayName}</p>
                                <p><strong>시작 시간:</strong> {new Date(record.startDateTime).toLocaleString()}</p>
                                <p><strong>종료 시간:</strong> {new Date(record.endDateTime).toLocaleString()}</p>
                                <p><strong>통화 유형:</strong> {record.modalities.join(', ')}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>통화 기록이 없습니다.</p>
                )}
            </div>

            {/* 모달 구현 */}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Messages in {selectedChatTopic}</h2>
                        {selectedChatMessages.length > 0 ? (
                            <ul>
                                {selectedChatMessages.map(message => (
                                    <li key={message.id}>
                                        {message.body.content || 'No message content'}
                                    </li>
                                ))}
                            </ul>
                        ) : <p>No messages available.</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default App;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-page {
  padding: 20px;
}

.page-title {
  margin-bottom: 20px;
}

.profile-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 992px) {
  .profile-container {
    flex-direction: row;
    align-items: flex-start;
  }

  .profile-card {
    width: 35%;
  }

  .profile-details {
    width: 65%;
  }
}

.profile-card {
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.profile-photo-container {
  margin-bottom: 16px;
}

.profile-photo {
  border-radius: 50%;
  overflow: hidden;
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.section-separator {
  margin: 16px 0;
}

.profile-actions {
  display: flex;
  gap: 10px;
}

.details-card {
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  margin-bottom: 10px;
  font-weight: 600;
}

/* 로딩 컨테이너는 이미 앱 전역 스타일에 정의됨 */ `, "",{"version":3,"sources":["webpack://./src/pages/Profile/ProfilePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE;IACE,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,wCAAwC;EACxC,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA,8BAA8B","sourcesContent":[".profile-page {\r\n  padding: 20px;\r\n}\r\n\r\n.page-title {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.profile-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 20px;\r\n}\r\n\r\n@media (min-width: 992px) {\r\n  .profile-container {\r\n    flex-direction: row;\r\n    align-items: flex-start;\r\n  }\r\n\r\n  .profile-card {\r\n    width: 35%;\r\n  }\r\n\r\n  .profile-details {\r\n    width: 65%;\r\n  }\r\n}\r\n\r\n.profile-card {\r\n  padding: 20px;\r\n  background-color: white;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  height: fit-content;\r\n}\r\n\r\n.profile-photo-container {\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.profile-photo {\r\n  border-radius: 50%;\r\n  overflow: hidden;\r\n}\r\n\r\n.profile-info {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 8px;\r\n}\r\n\r\n.section-separator {\r\n  margin: 16px 0;\r\n}\r\n\r\n.profile-actions {\r\n  display: flex;\r\n  gap: 10px;\r\n}\r\n\r\n.details-card {\r\n  padding: 20px;\r\n  background-color: white;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.card-title {\r\n  margin-bottom: 10px;\r\n  font-weight: 600;\r\n}\r\n\r\n/* 로딩 컨테이너는 이미 앱 전역 스타일에 정의됨 */ "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

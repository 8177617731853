import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';

const useGraphData = (endpoint) => {
  const { graphToken } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    if (!graphToken) return;

    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`https://graph.microsoft.com/v1.0${endpoint}`, {
        headers: { Authorization: `Bearer ${graphToken}` }
      });
      
      if (!response.ok) {
        throw new Error(`API 요청 실패: ${response.status}`);
      }
      
      const result = await response.json();
      setData(result.value || result);
    } catch (err) {
      console.error(`${endpoint} 데이터 가져오기 오류:`, err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [endpoint, graphToken]);

  useEffect(() => {
    if (graphToken) {
      fetchData();
    }
  }, [fetchData, graphToken]);

  const refresh = () => {
    fetchData();
  };

  return { data, loading, error, refresh };
};

export default useGraphData; 
import React, { useState } from 'react';
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    Text,
    SearchBox,
    Stack,
    DefaultButton
} from '@fluentui/react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import useGraphData from '../../hooks/useGraphData';
import EmailDetail from './EmailDetail';
import './EmailsPage.css';

const EmailsPage = () => {
    const { data: emails, loading, error, refresh } = useGraphData('/me/messages?$top=50');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedEmail, setSelectedEmail] = useState(null);
    const { openDetailPanel } = useOutletContext();

    // 이메일 필터링
    const filteredEmails = emails.filter(email =>
        email.subject?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email.from?.emailAddress?.address?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email.from?.emailAddress?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // 이메일 목록 컬럼 정의
    const columns = [
        {
            key: 'from',
            name: '보낸 사람',
            fieldName: 'from',
            minWidth: 150,
            maxWidth: 180,
            onRender: (item) => item.from?.emailAddress?.name || item.from?.emailAddress?.address || '(알 수 없음)'
        },
        {
            key: 'subject',
            name: '제목',
            fieldName: 'subject',
            minWidth: 200,
            isMultiline: true,
            onRender: (item) => item.subject || '(제목 없음)'
        },
        {
            key: 'receivedDateTime',
            name: '받은 날짜',
            fieldName: 'receivedDateTime',
            minWidth: 100,
            maxWidth: 120,
            onRender: (item) => new Date(item.receivedDateTime).toLocaleDateString()
        },
        {
            key: 'actions',
            name: '작업',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item) => (
                <DefaultButton
                    text="보기"
                    onClick={() => handleViewEmail(item)}
                />
            )
        }
    ];

    // 이메일 상세 보기
    const handleViewEmail = (email) => {
        setSelectedEmail(email);
        openDetailPanel('이메일 상세', <EmailDetail email={email} />);
    };

    if (loading) {
        return (
            <div className="loading-container">
                <Spinner size={SpinnerSize.large} label="이메일을 불러오는 중..." />
            </div>
        );
    }

    if (error) {
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                오류가 발생했습니다: {error}
            </MessageBar>
        );
    }

    return (
        <div className="emails-page">
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="page-header">
                <Text variant="xxLarge">이메일</Text>
                <DefaultButton text="새로고침" onClick={refresh} iconProps={{ iconName: 'Refresh' }} />
            </Stack>

            <SearchBox
                placeholder="이메일 검색..."
                onChange={(_, value) => setSearchQuery(value || '')}
                className="search-box"
            />

            <div className="emails-count">
                <Text>총 {filteredEmails.length}개의 이메일이 있습니다.</Text>
            </div>

            <div style={{ height: '600px', overflowY: 'auto', border: '1px solid #eee', padding: '10px' }}>
                {/* 기본 테이블 구조로 이메일 목록 표시 */}
                {filteredEmails.length > 0 && (
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #ddd', backgroundColor: '#f5f5f5' }}>
                                <th style={{ padding: '10px', textAlign: 'left' }}>보낸 사람</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>제목</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>받은 날짜</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>작업</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEmails.map(email => (
                                <tr key={email.id} style={{ borderBottom: '1px solid #eee' }}>
                                    <td style={{ padding: '10px' }}>
                                        {email.from?.emailAddress?.name || email.from?.emailAddress?.address || '(알 수 없음)'}
                                    </td>
                                    <td style={{ padding: '10px' }}>{email.subject || '(제목 없음)'}</td>
                                    <td style={{ padding: '10px' }}>{new Date(email.receivedDateTime).toLocaleDateString()}</td>
                                    <td style={{ padding: '10px' }}>
                                        <DefaultButton
                                            text="보기"
                                            onClick={() => handleViewEmail(email)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {/* DetailsList 컴포넌트 - 숨김 처리 */}
                <div style={{ display: 'none' }}>
                    <DetailsList
                        items={filteredEmails}
                        columns={columns}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        onRenderRow={(props) => {
                            const customStyles = {
                                root: {
                                    cursor: 'pointer',
                                    selectors: {
                                        '&:hover': {
                                            backgroundColor: '#f3f2f1'
                                        }
                                    }
                                }
                            };
                            return <div style={customStyles.root}>{props.children}</div>;
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default EmailsPage; 
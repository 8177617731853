// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-detail {
  padding: 10px;
}

.team-header {
  margin-bottom: 16px;
}

.team-name {
  font-weight: 600;
  margin-bottom: 8px;
}

.team-description {
  color: #666;
  margin-bottom: 8px;
}

.team-actions {
  margin: 16px 0;
}

.team-pivot {
  margin-top: 16px;
}

.pivot-content {
  margin-top: 16px;
  padding: 0 4px;
}

.section-title {
  font-weight: 600;
  margin-bottom: 12px;
}

.channels-list,
.members-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.channel-item,
.member-item {
  padding: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: background-color 0.2s;
}

.channel-item:hover,
.member-item:hover {
  background-color: #f0f0f0;
}

.channel-description {
  color: #666;
  font-size: 12px;
  margin-top: 4px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
} `, "",{"version":3,"sources":["webpack://./src/pages/Teams/TeamDetail.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;;EAEE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,iCAAiC;AACnC;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".team-detail {\r\n  padding: 10px;\r\n}\r\n\r\n.team-header {\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.team-name {\r\n  font-weight: 600;\r\n  margin-bottom: 8px;\r\n}\r\n\r\n.team-description {\r\n  color: #666;\r\n  margin-bottom: 8px;\r\n}\r\n\r\n.team-actions {\r\n  margin: 16px 0;\r\n}\r\n\r\n.team-pivot {\r\n  margin-top: 16px;\r\n}\r\n\r\n.pivot-content {\r\n  margin-top: 16px;\r\n  padding: 0 4px;\r\n}\r\n\r\n.section-title {\r\n  font-weight: 600;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.channels-list,\r\n.members-list {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 8px;\r\n}\r\n\r\n.channel-item,\r\n.member-item {\r\n  padding: 10px;\r\n  border-radius: 4px;\r\n  background-color: #f9f9f9;\r\n  transition: background-color 0.2s;\r\n}\r\n\r\n.channel-item:hover,\r\n.member-item:hover {\r\n  background-color: #f0f0f0;\r\n}\r\n\r\n.channel-description {\r\n  color: #666;\r\n  font-size: 12px;\r\n  margin-top: 4px;\r\n}\r\n\r\n.loading-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 400px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

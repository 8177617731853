import React, { useState } from 'react';
import { Stack, Panel, PanelType } from '@fluentui/react';
import LeftNavigation from './LeftNavigation';
import { Outlet } from 'react-router-dom';
import './AppLayout.css';

const AppLayout = () => {
  const [isDetailPanelOpen, setIsDetailPanelOpen] = useState(false);
  const [detailPanelContent, setDetailPanelContent] = useState(null);
  const [detailPanelTitle, setDetailPanelTitle] = useState('');

  const openDetailPanel = (title, content) => {
    setDetailPanelTitle(title);
    setDetailPanelContent(content);
    setIsDetailPanelOpen(true);
  };

  const dismissDetailPanel = () => {
    setIsDetailPanelOpen(false);
  };

  // 전역 컨텍스트를 통해 패널 열기 함수를 앱 전체에서 접근 가능하게 할 수 있습니다
  // 여기서는 속성으로 전달하는 방식으로 구현

  return (
    <Stack horizontal className="app-layout-container">
      <Stack.Item className="left-nav-container">
        <LeftNavigation />
      </Stack.Item>
      
      <Stack.Item className="main-content-container" grow>
        <Outlet context={{ openDetailPanel }} />
      </Stack.Item>
      
      <Panel
        isOpen={isDetailPanelOpen}
        onDismiss={dismissDetailPanel}
        headerText={detailPanelTitle}
        type={PanelType.medium}
        isLightDismiss
        closeButtonAriaLabel="닫기"
      >
        {detailPanelContent}
      </Panel>
    </Stack>
  );
};

export default AppLayout; 
import React from 'react';
import { 
  DocumentCard, 
  DocumentCardTitle, 
  DocumentCardActivity, 
  Stack, 
  Text, 
  Spinner, 
  SpinnerSize,
  MessageBar, 
  MessageBarType 
} from '@fluentui/react';
import { useAuth } from '../../context/AuthContext';
import useGraphData from '../../hooks/useGraphData';
import './HomePage.css';

const HomePage = () => {
  const { userInfo, isLoading: authLoading, error: authError } = useAuth();
  const { data: recentFiles, loading: filesLoading, error: filesError } = useGraphData('/me/drive/recent');
  const { data: events, loading: eventsLoading, error: eventsError } = useGraphData('/me/calendar/events?$top=5');
  const { data: messages, loading: messagesLoading, error: messagesError } = useGraphData('/me/messages?$top=5');

  const isLoading = authLoading || filesLoading || eventsLoading || messagesLoading;
  const hasError = authError || filesError || eventsError || messagesError;

  if (isLoading) {
    return (
      <div className="loading-container">
        <Spinner size={SpinnerSize.large} label="데이터를 불러오는 중..." />
      </div>
    );
  }

  if (hasError) {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        오류가 발생했습니다: {authError || filesError || eventsError || messagesError}
      </MessageBar>
    );
  }

  return (
    <div className="home-page">
      {userInfo && (
        <div className="welcome-section">
          <Text variant="xxLarge">안녕하세요, {userInfo.displayName}님!</Text>
          <Text variant="large">오늘도 좋은 하루 되세요.</Text>
        </div>
      )}

      <Stack tokens={{ childrenGap: 20 }}>
        {/* 최근 파일 섹션 */}
        <section className="recent-files-section">
          <Text variant="xLarge" className="section-title">최근 파일</Text>
          <div className="card-grid">
            {recentFiles && recentFiles.slice(0, 4).map((file) => (
              <DocumentCard key={file.id} className="file-card">
                <DocumentCardTitle title={file.name} />
                <DocumentCardActivity
                  activity={`마지막 수정: ${new Date(file.lastModifiedDateTime).toLocaleDateString()}`}
                  people={[{ name: file.lastModifiedBy?.user?.displayName || 'Unknown' }]}
                />
              </DocumentCard>
            ))}
          </div>
        </section>

        {/* 다가오는 일정 섹션 */}
        <section className="upcoming-events-section">
          <Text variant="xLarge" className="section-title">다가오는 일정</Text>
          <div className="card-grid">
            {events && events.slice(0, 4).map((event) => (
              <DocumentCard key={event.id} className="event-card">
                <DocumentCardTitle title={event.subject} />
                <DocumentCardActivity
                  activity={`${new Date(event.start.dateTime).toLocaleString()} - ${new Date(event.end.dateTime).toLocaleTimeString()}`}
                  people={[{ name: event.organizer?.emailAddress?.name || 'Unknown' }]}
                />
              </DocumentCard>
            ))}
          </div>
        </section>

        {/* 최근 메일 섹션 */}
        <section className="recent-emails-section">
          <Text variant="xLarge" className="section-title">최근 메일</Text>
          <div className="card-grid">
            {messages && messages.slice(0, 4).map((message) => (
              <DocumentCard key={message.id} className="email-card">
                <DocumentCardTitle title={message.subject || '(제목 없음)'} />
                <DocumentCardActivity
                  activity={`${new Date(message.receivedDateTime).toLocaleString()}`}
                  people={[{ name: message.from?.emailAddress?.name || 'Unknown' }]}
                />
              </DocumentCard>
            ))}
          </div>
        </section>
      </Stack>
    </div>
  );
};

export default HomePage; 
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-page {
  padding: 20px;
  height: 100%;
}

.page-header {
  margin-bottom: 20px;
}

.filters-container {
  margin-bottom: 20px;
}

.date-picker {
  width: 200px;
}

.search-box {
  width: 300px;
}

.events-count {
  margin-bottom: 10px;
  color: #666;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
} `, "",{"version":3,"sources":["webpack://./src/pages/Calendar/CalendarPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".calendar-page {\r\n  padding: 20px;\r\n  height: 100%;\r\n}\r\n\r\n.page-header {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.filters-container {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.date-picker {\r\n  width: 200px;\r\n}\r\n\r\n.search-box {\r\n  width: 300px;\r\n}\r\n\r\n.events-count {\r\n  margin-bottom: 10px;\r\n  color: #666;\r\n}\r\n\r\n.loading-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 400px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-detail {
  padding: 10px;
}

.event-subject {
  font-weight: 600;
  margin-bottom: 10px;
}

.event-info {
  margin: 16px 0;
}

.info-item {
  display: flex;
  gap: 12px;
}

.info-icon {
  margin-top: 5px;
  color: #0078d4;
  font-size: 16px;
}

.info-label {
  font-weight: 600;
  margin-bottom: 4px;
  display: block;
}

.meeting-link {
  color: #0078d4;
  text-decoration: none;
}

.meeting-link:hover {
  text-decoration: underline;
}

.section-title {
  font-weight: 600;
  margin-bottom: 12px;
}

.event-attendees {
  margin: 16px 0;
}

.attendees-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.attendee-item {
  margin-bottom: 4px;
}

.event-body {
  margin: 16px 0;
}

.html-content {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

.event-actions {
  margin-top: 16px;
} `, "",{"version":3,"sources":["webpack://./src/pages/Calendar/CalendarEventDetail.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;EACf,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".event-detail {\r\n  padding: 10px;\r\n}\r\n\r\n.event-subject {\r\n  font-weight: 600;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.event-info {\r\n  margin: 16px 0;\r\n}\r\n\r\n.info-item {\r\n  display: flex;\r\n  gap: 12px;\r\n}\r\n\r\n.info-icon {\r\n  margin-top: 5px;\r\n  color: #0078d4;\r\n  font-size: 16px;\r\n}\r\n\r\n.info-label {\r\n  font-weight: 600;\r\n  margin-bottom: 4px;\r\n  display: block;\r\n}\r\n\r\n.meeting-link {\r\n  color: #0078d4;\r\n  text-decoration: none;\r\n}\r\n\r\n.meeting-link:hover {\r\n  text-decoration: underline;\r\n}\r\n\r\n.section-title {\r\n  font-weight: 600;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.event-attendees {\r\n  margin: 16px 0;\r\n}\r\n\r\n.attendees-list {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 8px;\r\n  margin-top: 10px;\r\n}\r\n\r\n.attendee-item {\r\n  margin-bottom: 4px;\r\n}\r\n\r\n.event-body {\r\n  margin: 16px 0;\r\n}\r\n\r\n.html-content {\r\n  max-height: 300px;\r\n  overflow-y: auto;\r\n  padding-right: 10px;\r\n}\r\n\r\n.event-actions {\r\n  margin-top: 16px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-detail {
  padding: 10px;
}

.user-header {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.user-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 16px 0;
}

.user-info {
  margin-top: 20px;
}

.info-section {
  margin-bottom: 16px;
}

.section-title {
  font-weight: 600;
  margin-bottom: 12px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
} `, "",{"version":3,"sources":["webpack://./src/pages/Users/UserDetail.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".user-detail {\r\n  padding: 10px;\r\n}\r\n\r\n.user-header {\r\n  display: flex;\r\n  justify-content: center;\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.user-actions {\r\n  display: flex;\r\n  justify-content: center;\r\n  gap: 10px;\r\n  margin: 16px 0;\r\n}\r\n\r\n.user-info {\r\n  margin-top: 20px;\r\n}\r\n\r\n.info-section {\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.section-title {\r\n  font-weight: 600;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.loading-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 400px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

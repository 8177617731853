import React from 'react';
import {
  Stack,
  Text,
  Separator,
  Persona,
  PersonaSize,
  ActionButton,
  FontIcon,
  Label
} from '@fluentui/react';
import { useNotification } from '../../context/NotificationContext';
import './CallDetail.css';

const CallDetail = ({ callRecord }) => {
  const { showNotification } = useNotification();
  
  if (!callRecord) {
    return <Text>통화 기록 정보를 찾을 수 없습니다.</Text>;
  }
  
  // 통화 유형 표시
  const getCallTypeText = (type) => {
    switch(type) {
      case 'audio':
        return '음성 통화';
      case 'audioVideo':
        return '화상 통화';
      default:
        return type;
    }
  };
  
  // 통화 방향 표시
  const getCallDirectionText = (direction) => {
    return direction === 'incoming' ? '수신' : '발신';
  };
  
  // 통화 시간 포맷팅
  const formatDuration = (minutes) => {
    if (minutes < 60) {
      return `${minutes}분`;
    }
    
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    return `${hours}시간 ${remainingMinutes}분`;
  };
  
  // 참여자에게 전화 걸기
  const handleCallParticipant = (participant) => {
    if (participant && participant.email) {
      const deepLink = `https://teams.microsoft.com/l/call/0/0?users=${participant.email}`;
      window.open(deepLink, '_blank');
      showNotification({
        message: `${participant.displayName || '사용자'}에게 전화 연결 중...`,
        type: 'success'
      });
    } else {
      showNotification({
        message: '전화를 걸 수 없습니다. 사용자 정보가 부족합니다.',
        type: 'error'
      });
    }
  };
  
  // 참여자와 채팅 시작
  const handleChatWithParticipant = (participant) => {
    if (participant && participant.email) {
      const deepLink = `https://teams.microsoft.com/l/chat/0/0?users=${participant.email}`;
      window.open(deepLink, '_blank');
      showNotification({
        message: `${participant.displayName || '사용자'}와의 채팅을 시작합니다...`,
        type: 'success'
      });
    } else {
      showNotification({
        message: '채팅을 시작할 수 없습니다. 사용자 정보가 부족합니다.',
        type: 'error'
      });
    }
  };
  
  return (
    <div className="call-detail">
      <div className="call-header">
        <Text variant="xLarge" className="call-subject">
          {callRecord.subject || '(제목 없음)'}
        </Text>
      </div>
      
      <Separator />
      
      <Stack tokens={{ childrenGap: 16 }} className="call-info">
        {/* 통화 정보 */}
        <div className="info-section">
          <Text variant="mediumPlus" className="section-title">통화 정보</Text>
          
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack horizontal horizontalAlign="space-between">
              <Label>통화 유형</Label>
              <Text>{getCallTypeText(callRecord.type)}</Text>
            </Stack>
            
            <Stack horizontal horizontalAlign="space-between">
              <Label>통화 방향</Label>
              <Text>{getCallDirectionText(callRecord.direction)}</Text>
            </Stack>
            
            <Stack horizontal horizontalAlign="space-between">
              <Label>시작 시간</Label>
              <Text>{new Date(callRecord.startDateTime).toLocaleString()}</Text>
            </Stack>
            
            <Stack horizontal horizontalAlign="space-between">
              <Label>종료 시간</Label>
              <Text>{new Date(callRecord.endDateTime).toLocaleString()}</Text>
            </Stack>
            
            <Stack horizontal horizontalAlign="space-between">
              <Label>통화 시간</Label>
              <Text>{formatDuration(callRecord.duration)}</Text>
            </Stack>
          </Stack>
        </div>
        
        {/* 참여자 정보 */}
        <div className="info-section">
          <Text variant="mediumPlus" className="section-title">참여자</Text>
          
          <div className="participants-list">
            {callRecord.participants.map((participant, index) => (
              <div key={index} className="participant-item">
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                  <Persona
                    text={participant.displayName || '(이름 없음)'}
                    secondaryText={participant.email || ''}
                    size={PersonaSize.size32}
                  />
                  
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <ActionButton 
                      iconProps={{ iconName: 'Phone' }}
                      onClick={() => handleCallParticipant(participant)}
                    >
                      전화
                    </ActionButton>
                    <ActionButton 
                      iconProps={{ iconName: 'Chat' }}
                      onClick={() => handleChatWithParticipant(participant)}
                    >
                      채팅
                    </ActionButton>
                  </Stack>
                </Stack>
              </div>
            ))}
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default CallDetail; 
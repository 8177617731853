import React, { useState } from 'react';
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    Text,
    SearchBox,
    Stack,
    DefaultButton,
    Persona,
    PersonaSize
} from '@fluentui/react';
import { useOutletContext } from 'react-router-dom';
import useGraphData from '../../hooks/useGraphData';
import ChatDetail from './ChatDetail';
import './ChatsPage.css';

const ChatsPage = () => {
    const { data: chats, loading, error, refresh } = useGraphData('/me/chats');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedChat, setSelectedChat] = useState(null);
    const { openDetailPanel } = useOutletContext();

    // 채팅 필터링
    const filteredChats = chats.filter(chat =>
        chat.topic?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        chat.chatType?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // 채팅 목록 컬럼 정의
    const columns = [
        {
            key: 'type',
            name: '유형',
            fieldName: 'chatType',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item) => {
                let typeName = '알 수 없음';
                switch (item.chatType) {
                    case 'oneOnOne':
                        typeName = '1:1 채팅';
                        break;
                    case 'group':
                        typeName = '그룹 채팅';
                        break;
                    case 'meeting':
                        typeName = '미팅 채팅';
                        break;
                    default:
                        typeName = item.chatType || '알 수 없음';
                }
                return typeName;
            }
        },
        {
            key: 'topic',
            name: '대화명',
            fieldName: 'topic',
            minWidth: 200,
            isMultiline: true,
            onRender: (item) => item.topic || '(대화명 없음)'
        },
        {
            key: 'lastUpdateDateTime',
            name: '마지막 활동',
            fieldName: 'lastUpdateDateTime',
            minWidth: 120,
            maxWidth: 120,
            onRender: (item) => new Date(item.lastUpdateDateTime).toLocaleString()
        },
        {
            key: 'participants',
            name: '참여자',
            minWidth: 150,
            onRender: (item) => {
                const participantCount = item.members?.length || 0;
                return `${participantCount}명`;
            }
        },
        {
            key: 'actions',
            name: '작업',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item) => (
                <DefaultButton
                    text="보기"
                    onClick={() => handleViewChat(item)}
                />
            )
        }
    ];

    // 채팅 상세 보기
    const handleViewChat = (chat) => {
        setSelectedChat(chat);
        openDetailPanel(`채팅: ${chat.topic || '제목 없음'}`, <ChatDetail chatId={chat.id} />);
    };

    if (loading) {
        return (
            <div className="loading-container">
                <Spinner size={SpinnerSize.large} label="채팅을 불러오는 중..." />
            </div>
        );
    }

    if (error) {
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                오류가 발생했습니다: {error}
            </MessageBar>
        );
    }

    return (
        <div className="chats-page">
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="page-header">
                <Text variant="xxLarge">채팅</Text>
                <DefaultButton text="새로고침" onClick={refresh} iconProps={{ iconName: 'Refresh' }} />
            </Stack>

            <SearchBox
                placeholder="채팅 검색..."
                onChange={(_, value) => setSearchQuery(value || '')}
                className="search-box"
            />

            <div className="chats-count">
                <Text>총 {filteredChats.length}개의 채팅이 있습니다.</Text>
            </div>

            <div style={{ height: '600px', overflowY: 'auto', border: '1px solid #eee', padding: '10px' }}>
                {/* 기본 테이블 구조로 채팅 목록 표시 */}
                {filteredChats.length > 0 && (
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #ddd', backgroundColor: '#f5f5f5' }}>
                                <th style={{ padding: '10px', textAlign: 'left' }}>유형</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>대화명</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>마지막 활동</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>참여자</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>작업</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredChats.map(chat => (
                                <tr key={chat.id} style={{ borderBottom: '1px solid #eee' }}>
                                    <td style={{ padding: '10px' }}>
                                        {(() => {
                                            let typeName = '알 수 없음';
                                            switch (chat.chatType) {
                                                case 'oneOnOne':
                                                    typeName = '1:1 채팅';
                                                    break;
                                                case 'group':
                                                    typeName = '그룹 채팅';
                                                    break;
                                                case 'meeting':
                                                    typeName = '미팅 채팅';
                                                    break;
                                                default:
                                                    typeName = chat.chatType || '알 수 없음';
                                            }
                                            return typeName;
                                        })()}
                                    </td>
                                    <td style={{ padding: '10px' }}>{chat.topic || '(대화명 없음)'}</td>
                                    <td style={{ padding: '10px' }}>{new Date(chat.lastUpdateDateTime).toLocaleString()}</td>
                                    <td style={{ padding: '10px' }}>{`${chat.members?.length || 0}명`}</td>
                                    <td style={{ padding: '10px' }}>
                                        <DefaultButton
                                            text="보기"
                                            onClick={() => handleViewChat(chat)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {/* DetailsList 컴포넌트 - 숨김 처리 */}
                <div style={{ display: 'none' }}>
                    <DetailsList
                        items={filteredChats}
                        columns={columns}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        onRenderRow={(props) => {
                            const customStyles = {
                                root: {
                                    cursor: 'pointer',
                                    selectors: {
                                        '&:hover': {
                                            backgroundColor: '#f3f2f1'
                                        }
                                    }
                                }
                            };
                            return <div style={customStyles.root}>{props.children}</div>;
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ChatsPage; 
import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

// 인증 컨텍스트 생성
const AuthContext = createContext();

// 인증 프로바이더 컴포넌트
export const AuthProvider = ({ children }) => {
  const [graphToken, setGraphToken] = useState(null);
  const [teamsToken, setTeamsToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Teams 초기화 및 인증
  useEffect(() => {
    const initializeTeams = async () => {
      try {
        setIsLoading(true);
        await microsoftTeams.app.initialize();
        
        microsoftTeams.authentication.getAuthToken({
          successCallback: (token) => {
            console.log('Teams Token obtained');
            setTeamsToken(token);
            fetchGraphToken(token);
          },
          failureCallback: (error) => {
            console.error("Failed to get token", error);
            setError("인증 토큰을 얻는데 실패했습니다");
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.error("Teams initialization failed", error);
        setError("Teams 초기화에 실패했습니다");
        setIsLoading(false);
      }
    };

    initializeTeams();
  }, []);

  // Graph API 토큰 가져오기
  const fetchGraphToken = useCallback((teamsToken) => {
    fetch('https://spo.in-bridge.net/api/get-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: teamsToken })
    })
    .then(response => response.json())
    .then(data => {
      if (data.accessToken) {
        console.log('Graph API Access Token obtained');
        setGraphToken(data.accessToken);
        setIsAuthenticated(true);
        fetchUserInfo(data.accessToken);
      } else {
        setError('서버에서 Graph API 토큰을 가져오지 못했습니다');
      }
      setIsLoading(false);
    })
    .catch(error => {
      console.error('Error fetching Graph API token:', error);
      setError('Graph API 토큰을 가져오는 중 오류가 발생했습니다');
      setIsLoading(false);
    });
  }, []);

  // 사용자 정보 가져오기
  const fetchUserInfo = useCallback((token) => {
    fetch('https://graph.microsoft.com/v1.0/me', {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => response.json())
    .then(data => setUserInfo(data))
    .catch(error => setError("사용자 정보를 가져오는데 실패했습니다"));
  }, []);

  // 컨텍스트 값
  const value = {
    graphToken,
    teamsToken,
    userInfo,
    isAuthenticated,
    isLoading,
    error
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

// 인증 컨텍스트 사용을 위한 훅
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext; 
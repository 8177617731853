import React, { useState, useEffect } from 'react';
import {
  Stack,
  Text,
  TextField,
  PrimaryButton,
  DefaultButton,
  Checkbox,
  IconButton,
  List,
  Panel,
  PanelType,
  DatePicker,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType
} from '@fluentui/react';
import { useNotification } from '../context/NotificationContext';
import './Todo.css';

const Todo = () => {
  const [todos, setTodos] = useState([]);
  const [newTodoText, setNewTodoText] = useState('');
  const [newTodoDueDate, setNewTodoDueDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingTodo, setEditingTodo] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  
  const { showNotification } = useNotification();
  
  // 초기 데이터 로드 (실제로는 API 호출)
  useEffect(() => {
    setLoading(true);
    
    // 예시 데이터
    const sampleTodos = [
      { id: 1, text: '프로젝트 계획서 작성', completed: false, dueDate: new Date(2023, 6, 15) },
      { id: 2, text: '팀 미팅 준비', completed: true, dueDate: new Date(2023, 6, 10) },
      { id: 3, text: '보고서 검토', completed: false, dueDate: new Date(2023, 6, 20) }
    ];
    
    // API 호출 시뮬레이션
    setTimeout(() => {
      setTodos(sampleTodos);
      setLoading(false);
    }, 1000);
  }, []);
  
  // 할 일 추가
  const handleAddTodo = () => {
    if (!newTodoText.trim()) {
      showNotification({
        message: '할 일 내용을 입력해주세요.',
        type: 'warning'
      });
      return;
    }
    
    const newTodo = {
      id: Date.now(),
      text: newTodoText,
      completed: false,
      dueDate: newTodoDueDate
    };
    
    setTodos([...todos, newTodo]);
    setNewTodoText('');
    setNewTodoDueDate(null);
    
    showNotification({
      message: '새로운 할 일이 추가되었습니다.',
      type: 'success'
    });
  };
  
  // 할 일 완료 상태 토글
  const handleToggleComplete = (id) => {
    const updatedTodos = todos.map(todo => 
      todo.id === id ? { ...todo, completed: !todo.completed } : todo
    );
    
    setTodos(updatedTodos);
    
    const todo = todos.find(t => t.id === id);
    const newStatus = !todo.completed;
    
    showNotification({
      message: `"${todo.text}" ${newStatus ? '완료됨' : '미완료로 변경됨'}`,
      type: 'info'
    });
  };
  
  // 할 일 삭제
  const handleDeleteTodo = (id) => {
    const todoToDelete = todos.find(t => t.id === id);
    const updatedTodos = todos.filter(todo => todo.id !== id);
    
    setTodos(updatedTodos);
    
    showNotification({
      message: `"${todoToDelete.text}" 삭제됨`,
      type: 'success'
    });
  };
  
  // 할 일 편집 패널 열기
  const handleOpenEditPanel = (todo) => {
    setEditingTodo({ ...todo });
    setIsPanelOpen(true);
  };
  
  // 할 일 업데이트
  const handleUpdateTodo = () => {
    if (!editingTodo.text.trim()) {
      showNotification({
        message: '할 일 내용을 입력해주세요.',
        type: 'warning'
      });
      return;
    }
    
    const updatedTodos = todos.map(todo => 
      todo.id === editingTodo.id ? editingTodo : todo
    );
    
    setTodos(updatedTodos);
    setIsPanelOpen(false);
    
    showNotification({
      message: '할 일이 업데이트되었습니다.',
      type: 'success'
    });
  };
  
  // 할 일 렌더링
  const renderTodo = (item) => {
    return (
      <div className={`todo-item ${item.completed ? 'completed' : ''}`}>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
            <Checkbox 
              checked={item.completed} 
              onChange={() => handleToggleComplete(item.id)}
            />
            <Stack>
              <Text className={item.completed ? 'completed-text' : ''}>
                {item.text}
              </Text>
              {item.dueDate && (
                <Text className="due-date">
                  마감일: {item.dueDate.toLocaleDateString()}
                </Text>
              )}
            </Stack>
          </Stack>
          
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <IconButton 
              iconProps={{ iconName: 'Edit' }} 
              title="편집" 
              onClick={() => handleOpenEditPanel(item)}
            />
            <IconButton 
              iconProps={{ iconName: 'Delete' }} 
              title="삭제" 
              onClick={() => handleDeleteTodo(item.id)}
            />
          </Stack>
        </Stack>
      </div>
    );
  };
  
  if (loading) {
    return (
      <div className="loading-container">
        <Spinner size={SpinnerSize.large} label="할 일 목록을 불러오는 중..." />
      </div>
    );
  }
  
  if (error) {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        오류가 발생했습니다: {error}
      </MessageBar>
    );
  }
  
  return (
    <div className="todo-container">
      <Text variant="xxLarge" className="page-title">할 일 목록</Text>
      
      <Stack horizontal tokens={{ childrenGap: 8 }} className="add-todo-form">
        <TextField 
          placeholder="새로운 할 일 추가..." 
          value={newTodoText}
          onChange={(_, value) => setNewTodoText(value)}
          className="todo-input"
        />
        <DatePicker 
          placeholder="마감일 선택" 
          value={newTodoDueDate}
          onSelectDate={date => setNewTodoDueDate(date)}
          className="date-picker"
        />
        <PrimaryButton text="추가" onClick={handleAddTodo} />
      </Stack>
      
      <div className="todo-list">
        <Text variant="large" className="section-title">
          할 일 ({todos.filter(t => !t.completed).length})
        </Text>
        <List 
          items={todos.filter(t => !t.completed)} 
          onRenderCell={renderTodo}
        />
        
        {todos.some(t => t.completed) && (
          <>
            <Text variant="large" className="section-title completed-section">
              완료된 항목 ({todos.filter(t => t.completed).length})
            </Text>
            <List 
              items={todos.filter(t => t.completed)} 
              onRenderCell={renderTodo}
            />
          </>
        )}
      </div>
      
      <Panel
        isOpen={isPanelOpen}
        onDismiss={() => setIsPanelOpen(false)}
        headerText="할 일 편집"
        type={PanelType.medium}
      >
        {editingTodo && (
          <Stack tokens={{ childrenGap: 16 }} className="edit-form">
            <TextField 
              label="할 일 내용"
              value={editingTodo.text}
              onChange={(_, value) => setEditingTodo({...editingTodo, text: value})}
            />
            
            <DatePicker 
              label="마감일"
              value={editingTodo.dueDate}
              onSelectDate={date => setEditingTodo({...editingTodo, dueDate: date})}
            />
            
            <Checkbox 
              label="완료됨"
              checked={editingTodo.completed}
              onChange={(_, checked) => setEditingTodo({...editingTodo, completed: checked})}
            />
            
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <PrimaryButton text="저장" onClick={handleUpdateTodo} />
              <DefaultButton text="취소" onClick={() => setIsPanelOpen(false)} />
            </Stack>
          </Stack>
        )}
      </Panel>
    </div>
  );
};

export default Todo; 
import React, { useState } from 'react';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  Text,
  SearchBox,
  Stack,
  DefaultButton,
  DatePicker,
  IconButton,
  Persona,
  PersonaSize
} from '@fluentui/react';
import { useOutletContext } from 'react-router-dom';
import useGraphData from '../../hooks/useGraphData';
import CallDetail from './CallDetail';
import { useNotification } from '../../context/NotificationContext';
import './CallRecordsPage.css';

const CallRecordsPage = () => {
  // 통화 기록은 Graph API에서 직접 가져올 수 없으므로 서버 API를 통해 가져와야 합니다.
  // 여기서는 예시 데이터를 사용합니다.
  const [callRecords, setCallRecords] = useState([
    {
      id: '1',
      startDateTime: new Date(2023, 5, 15, 10, 30).toISOString(),
      endDateTime: new Date(2023, 5, 15, 11, 0).toISOString(),
      type: 'audioVideo',
      participants: [
        { displayName: '홍길동', email: 'hong@example.com' },
        { displayName: '김철수', email: 'kim@example.com' }
      ],
      direction: 'outgoing',
      duration: 30,
      subject: '프로젝트 회의'
    },
    {
      id: '2',
      startDateTime: new Date(2023, 5, 16, 14, 0).toISOString(),
      endDateTime: new Date(2023, 5, 16, 14, 15).toISOString(),
      type: 'audio',
      participants: [
        { displayName: '이영희', email: 'lee@example.com' }
      ],
      direction: 'incoming',
      duration: 15,
      subject: '고객 문의'
    }
  ]);
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { openDetailPanel } = useOutletContext();
  const { showNotification } = useNotification();

  // 통화 기록 필터링
  const filteredCallRecords = callRecords.filter(record => 
    record.subject?.toLowerCase().includes(searchQuery.toLowerCase()) || 
    record.participants.some(p => 
      p.displayName?.toLowerCase().includes(searchQuery.toLowerCase()) || 
      p.email?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // 통화 기록 목록 컬럼 정의
  const columns = [
    { 
      key: 'participants', 
      name: '참여자', 
      minWidth: 200,
      onRender: (item) => (
        <div className="participants-container">
          {item.participants.map((participant, index) => (
            <Persona
              key={index}
              text={participant.displayName || '(이름 없음)'}
              secondaryText={participant.email || ''}
              size={PersonaSize.size24}
              className="participant-item"
            />
          ))}
        </div>
      )
    },
    { 
      key: 'subject', 
      name: '제목', 
      fieldName: 'subject', 
      minWidth: 150,
      onRender: (item) => item.subject || '(제목 없음)'
    },
    { 
      key: 'startDateTime', 
      name: '시작 시간', 
      fieldName: 'startDateTime', 
      minWidth: 120,
      onRender: (item) => new Date(item.startDateTime).toLocaleString()
    },
    { 
      key: 'duration', 
      name: '통화 시간', 
      fieldName: 'duration', 
      minWidth: 80,
      onRender: (item) => `${item.duration}분`
    },
    { 
      key: 'type', 
      name: '유형', 
      fieldName: 'type', 
      minWidth: 100,
      onRender: (item) => {
        switch(item.type) {
          case 'audio':
            return '음성 통화';
          case 'audioVideo':
            return '화상 통화';
          default:
            return item.type;
        }
      }
    },
    { 
      key: 'direction', 
      name: '방향', 
      fieldName: 'direction', 
      minWidth: 80,
      onRender: (item) => item.direction === 'incoming' ? '수신' : '발신'
    },
    {
      key: 'actions',
      name: '작업',
      minWidth: 100,
      onRender: (item) => (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <DefaultButton 
            text="상세 정보" 
            onClick={() => handleViewCallRecord(item)}
          />
          {item.participants.length === 1 && (
            <IconButton 
              iconProps={{ iconName: 'Phone' }} 
              title="전화 걸기"
              onClick={() => handleCallParticipant(item.participants[0])}
            />
          )}
        </Stack>
      )
    }
  ];

  // 통화 기록 상세 보기
  const handleViewCallRecord = (record) => {
    openDetailPanel(`통화 기록: ${record.subject || '제목 없음'}`, <CallDetail callRecord={record} />);
  };

  // 참여자에게 전화 걸기
  const handleCallParticipant = (participant) => {
    if (participant && participant.email) {
      const deepLink = `https://teams.microsoft.com/l/call/0/0?users=${participant.email}`;
      window.open(deepLink, '_blank');
      showNotification({
        message: `${participant.displayName || '사용자'}에게 전화 연결 중...`,
        type: 'success'
      });
    } else {
      showNotification({
        message: '전화를 걸 수 없습니다. 사용자 정보가 부족합니다.',
        type: 'error'
      });
    }
  };

  // 통화 기록 새로고침 (실제로는 API 호출)
  const handleRefresh = () => {
    setLoading(true);
    
    // 실제 구현에서는 API 호출
    setTimeout(() => {
      setLoading(false);
      showNotification({
        message: '통화 기록이 새로고침되었습니다.',
        type: 'success'
      });
    }, 1000);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner size={SpinnerSize.large} label="통화 기록을 불러오는 중..." />
      </div>
    );
  }

  if (error) {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        오류가 발생했습니다: {error}
      </MessageBar>
    );
  }

  return (
    <div className="call-records-page">
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="page-header">
        <Text variant="xxLarge">통화 기록</Text>
        <DefaultButton text="새로고침" onClick={handleRefresh} iconProps={{ iconName: 'Refresh' }} />
      </Stack>

      <SearchBox
        placeholder="통화 기록 검색..."
        onChange={(_, value) => setSearchQuery(value || '')}
        className="search-box"
      />

      <div className="call-records-count">
        <Text>총 {filteredCallRecords.length}개의 통화 기록이 있습니다.</Text>
      </div>

      <DetailsList
        items={filteredCallRecords}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        onRenderRow={(props) => {
          const customStyles = {
            root: {
              cursor: 'pointer',
              selectors: {
                '&:hover': {
                  backgroundColor: '#f3f2f1'
                }
              }
            }
          };
          return <div style={customStyles.root}>{props.children}</div>;
        }}
      />
    </div>
  );
};

export default CallRecordsPage; 
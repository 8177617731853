// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chats-page {
  padding: 20px;
  height: 100%;
}

.page-header {
  margin-bottom: 20px;
}

.search-box {
  margin-bottom: 16px;
  width: 300px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
} `, "",{"version":3,"sources":["webpack://./src/pages/Chats/ChatsPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".chats-page {\r\n  padding: 20px;\r\n  height: 100%;\r\n}\r\n\r\n.page-header {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.search-box {\r\n  margin-bottom: 16px;\r\n  width: 300px;\r\n}\r\n\r\n.loading-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 400px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.todo-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.page-title {
  margin-bottom: 24px;
}

.add-todo-form {
  margin-bottom: 24px;
  align-items: flex-end;
}

.todo-input {
  flex: 1 1;
}

.date-picker {
  width: 200px;
}

.todo-list {
  margin-top: 16px;
}

.section-title {
  margin-bottom: 12px;
  font-weight: 600;
}

.completed-section {
  margin-top: 24px;
  color: #666;
}

.todo-item {
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #f5f5f5;
  transition: background-color 0.2s ease;
}

.todo-item:hover {
  background-color: #e8e8e8;
}

.todo-item.completed {
  background-color: #f0f0f0;
  opacity: 0.8;
}

.completed-text {
  text-decoration: line-through;
  color: #666;
}

.due-date {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
}

.edit-form {
  padding: 16px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
} `, "",{"version":3,"sources":["webpack://./src/components/Todo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".todo-container {\r\n  padding: 20px;\r\n  max-width: 800px;\r\n  margin: 0 auto;\r\n}\r\n\r\n.page-title {\r\n  margin-bottom: 24px;\r\n}\r\n\r\n.add-todo-form {\r\n  margin-bottom: 24px;\r\n  align-items: flex-end;\r\n}\r\n\r\n.todo-input {\r\n  flex: 1;\r\n}\r\n\r\n.date-picker {\r\n  width: 200px;\r\n}\r\n\r\n.todo-list {\r\n  margin-top: 16px;\r\n}\r\n\r\n.section-title {\r\n  margin-bottom: 12px;\r\n  font-weight: 600;\r\n}\r\n\r\n.completed-section {\r\n  margin-top: 24px;\r\n  color: #666;\r\n}\r\n\r\n.todo-item {\r\n  padding: 12px;\r\n  border-radius: 4px;\r\n  margin-bottom: 8px;\r\n  background-color: #f5f5f5;\r\n  transition: background-color 0.2s ease;\r\n}\r\n\r\n.todo-item:hover {\r\n  background-color: #e8e8e8;\r\n}\r\n\r\n.todo-item.completed {\r\n  background-color: #f0f0f0;\r\n  opacity: 0.8;\r\n}\r\n\r\n.completed-text {\r\n  text-decoration: line-through;\r\n  color: #666;\r\n}\r\n\r\n.due-date {\r\n  font-size: 12px;\r\n  color: #666;\r\n  margin-top: 4px;\r\n}\r\n\r\n.edit-form {\r\n  padding: 16px;\r\n}\r\n\r\n.loading-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 300px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import {
  Stack,
  Text,
  Separator,
  Persona,
  PersonaSize,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  DefaultButton,
  ActionButton,
  Label,
  Link,
  FontIcon
} from '@fluentui/react';
import useGraphData from '../../hooks/useGraphData';
import { useNotification } from '../../context/NotificationContext';
import './UserDetail.css';

const UserDetail = ({ userId }) => {
  const { data: user, loading, error } = useGraphData(`/users/${userId}`);
  const { data: photoData, loading: photoLoading, error: photoError } = useGraphData(`/users/${userId}/photo/$value`);
  const { showNotification } = useNotification();
  
  const isLoading = loading || photoLoading;
  const hasError = error || photoError;
  
  // Teams 통화 기능
  const handleCallUser = () => {
    if (user && user.userPrincipalName) {
      const deepLink = `https://teams.microsoft.com/l/call/0/0?users=${user.userPrincipalName}`;
      window.open(deepLink, '_blank');
      showNotification({
        message: `${user.displayName || '사용자'}에게 전화 연결 중...`,
        type: 'success'
      });
    } else {
      showNotification({
        message: '전화를 걸 수 없습니다. 사용자 정보가 부족합니다.',
        type: 'error'
      });
    }
  };
  
  // Teams 채팅 기능
  const handleChatUser = () => {
    if (user && user.userPrincipalName) {
      const deepLink = `https://teams.microsoft.com/l/chat/0/0?users=${user.userPrincipalName}`;
      window.open(deepLink, '_blank');
      showNotification({
        message: `${user.displayName || '사용자'}와의 채팅을 시작합니다...`,
        type: 'success'
      });
    } else {
      showNotification({
        message: '채팅을 시작할 수 없습니다. 사용자 정보가 부족합니다.',
        type: 'error'
      });
    }
  };
  
  // 이메일 보내기
  const handleSendEmail = () => {
    if (user && (user.mail || user.userPrincipalName)) {
      const email = user.mail || user.userPrincipalName;
      window.open(`mailto:${email}`);
      showNotification({
        message: `${user.displayName || '사용자'}에게 이메일을 작성합니다...`,
        type: 'info'
      });
    } else {
      showNotification({
        message: '이메일을 보낼 수 없습니다. 이메일 주소가 없습니다.',
        type: 'error'
      });
    }
  };
  
  if (isLoading) {
    return (
      <div className="loading-container">
        <Spinner size={SpinnerSize.large} label="사용자 정보를 불러오는 중..." />
      </div>
    );
  }
  
  if (hasError) {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        오류가 발생했습니다: {error || photoError}
      </MessageBar>
    );
  }
  
  if (!user) {
    return (
      <MessageBar messageBarType={MessageBarType.warning}>
        사용자 정보를 찾을 수 없습니다.
      </MessageBar>
    );
  }
  
  return (
    <div className="user-detail">
      <div className="user-header">
        <Persona
          imageUrl={photoData ? URL.createObjectURL(new Blob([photoData])) : undefined}
          text={user.displayName || '(이름 없음)'}
          secondaryText={user.jobTitle || ''}
          tertiaryText={user.mail || user.userPrincipalName || ''}
          size={PersonaSize.size72}
        />
      </div>
      
      <div className="user-actions">
        <ActionButton iconProps={{ iconName: 'Phone' }} onClick={handleCallUser}>
          전화 걸기
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Chat' }} onClick={handleChatUser}>
          채팅 시작
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Mail' }} onClick={handleSendEmail}>
          이메일 보내기
        </ActionButton>
      </div>
      
      <Separator />
      
      <Stack tokens={{ childrenGap: 16 }} className="user-info">
        {/* 기본 정보 */}
        <div className="info-section">
          <Text variant="mediumPlus" className="section-title">기본 정보</Text>
          
          <Stack tokens={{ childrenGap: 8 }}>
            {user.displayName && (
              <Stack horizontal horizontalAlign="space-between">
                <Label>이름</Label>
                <Text>{user.displayName}</Text>
              </Stack>
            )}
            
            {user.jobTitle && (
              <Stack horizontal horizontalAlign="space-between">
                <Label>직책</Label>
                <Text>{user.jobTitle}</Text>
              </Stack>
            )}
            
            {user.department && (
              <Stack horizontal horizontalAlign="space-between">
                <Label>부서</Label>
                <Text>{user.department}</Text>
              </Stack>
            )}
            
            {user.officeLocation && (
              <Stack horizontal horizontalAlign="space-between">
                <Label>사무실</Label>
                <Text>{user.officeLocation}</Text>
              </Stack>
            )}
          </Stack>
        </div>
        
        {/* 연락처 정보 */}
        <div className="info-section">
          <Text variant="mediumPlus" className="section-title">연락처 정보</Text>
          
          <Stack tokens={{ childrenGap: 8 }}>
            {(user.mail || user.userPrincipalName) && (
              <Stack horizontal horizontalAlign="space-between">
                <Label>이메일</Label>
                <Link href={`mailto:${user.mail || user.userPrincipalName}`}>
                  {user.mail || user.userPrincipalName}
                </Link>
              </Stack>
            )}
            
            {user.businessPhones && user.businessPhones.length > 0 && (
              <Stack horizontal horizontalAlign="space-between">
                <Label>사무실 전화</Label>
                <Link href={`tel:${user.businessPhones[0]}`}>
                  {user.businessPhones[0]}
                </Link>
              </Stack>
            )}
            
            {user.mobilePhone && (
              <Stack horizontal horizontalAlign="space-between">
                <Label>휴대폰</Label>
                <Link href={`tel:${user.mobilePhone}`}>
                  {user.mobilePhone}
                </Link>
              </Stack>
            )}
          </Stack>
        </div>
        
        {/* 계정 정보 */}
        <div className="info-section">
          <Text variant="mediumPlus" className="section-title">계정 정보</Text>
          
          <Stack tokens={{ childrenGap: 8 }}>
            {user.id && (
              <Stack horizontal horizontalAlign="space-between">
                <Label>ID</Label>
                <Text>{user.id}</Text>
              </Stack>
            )}
            
            {user.userPrincipalName && (
              <Stack horizontal horizontalAlign="space-between">
                <Label>계정명</Label>
                <Text>{user.userPrincipalName}</Text>
              </Stack>
            )}
          </Stack>
        </div>
      </Stack>
    </div>
  );
};

export default UserDetail; 
import React, { useState } from 'react';
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    Text,
    SearchBox,
    Stack,
    DefaultButton,
    IconButton
} from '@fluentui/react';
import { useOutletContext } from 'react-router-dom';
import useGraphData from '../../hooks/useGraphData';
import TeamDetail from './TeamDetail';
import { useNotification } from '../../context/NotificationContext';
import './TeamsPage.css';

const TeamsPage = () => {
    const { data: teams, loading, error, refresh } = useGraphData('/me/joinedTeams');
    const [searchQuery, setSearchQuery] = useState('');
    const { openDetailPanel } = useOutletContext();
    const { showNotification } = useNotification();

    // 팀 필터링
    const filteredTeams = teams.filter(team =>
        team.displayName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        team.description?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // 팀 목록 컬럼 정의
    const columns = [
        {
            key: 'displayName',
            name: '팀 이름',
            fieldName: 'displayName',
            minWidth: 200,
            isMultiline: true,
            onRender: (item) => item.displayName || '(이름 없음)'
        },
        {
            key: 'description',
            name: '설명',
            fieldName: 'description',
            minWidth: 300,
            isMultiline: true,
            onRender: (item) => item.description || '(설명 없음)'
        },
        {
            key: 'actions',
            name: '작업',
            minWidth: 150,
            onRender: (item) => (
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <DefaultButton
                        text="상세 정보"
                        onClick={() => handleViewTeam(item)}
                    />
                    <IconButton
                        iconProps={{ iconName: 'TeamsLogo' }}
                        title="Teams에서 열기"
                        onClick={() => handleOpenInTeams(item)}
                    />
                </Stack>
            )
        }
    ];

    // 팀 상세 보기
    const handleViewTeam = (team) => {
        openDetailPanel(`팀: ${team.displayName || '이름 없음'}`, <TeamDetail teamId={team.id} />);
    };

    // Teams에서 팀 열기
    const handleOpenInTeams = (team) => {
        if (team && team.id) {
            const deepLink = `https://teams.microsoft.com/l/team/${team.id}/conversations?groupId=${team.id}`;
            window.open(deepLink, '_blank');
            showNotification({
                message: `${team.displayName || '팀'}을 Teams에서 열고 있습니다...`,
                type: 'info'
            });
        } else {
            showNotification({
                message: 'Teams에서 팀을 열 수 없습니다. 팀 정보가 부족합니다.',
                type: 'error'
            });
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <Spinner size={SpinnerSize.large} label="팀 목록을 불러오는 중..." />
            </div>
        );
    }

    if (error) {
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                오류가 발생했습니다: {error}
            </MessageBar>
        );
    }

    return (
        <div className="teams-page">
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="page-header">
                <Text variant="xxLarge">팀</Text>
                <DefaultButton text="새로고침" onClick={refresh} iconProps={{ iconName: 'Refresh' }} />
            </Stack>

            <SearchBox
                placeholder="팀 검색..."
                onChange={(_, value) => setSearchQuery(value || '')}
                className="search-box"
            />

            <div className="teams-count">
                <Text>총 {filteredTeams.length}개의 팀이 있습니다.</Text>
            </div>

            <div style={{ height: '600px', overflowY: 'auto', border: '1px solid #eee', padding: '10px' }}>
                {/* 기본 테이블 구조로 팀 목록 표시 */}
                {filteredTeams.length > 0 && (
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #ddd', backgroundColor: '#f5f5f5' }}>
                                <th style={{ padding: '10px', textAlign: 'left' }}>팀 이름</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>설명</th>
                                <th style={{ padding: '10px', textAlign: 'left' }}>작업</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTeams.map(team => (
                                <tr key={team.id} style={{ borderBottom: '1px solid #eee' }}>
                                    <td style={{ padding: '10px' }}>{team.displayName || '(이름 없음)'}</td>
                                    <td style={{ padding: '10px' }}>{team.description || '(설명 없음)'}</td>
                                    <td style={{ padding: '10px' }}>
                                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                                            <DefaultButton
                                                text="상세 정보"
                                                onClick={() => handleViewTeam(team)}
                                            />
                                            <IconButton
                                                iconProps={{ iconName: 'TeamsLogo' }}
                                                title="Teams에서 열기"
                                                onClick={() => handleOpenInTeams(team)}
                                            />
                                        </Stack>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {/* DetailsList 컴포넌트 - 숨김 처리 */}
                <div style={{ display: 'none' }}>
                    <DetailsList
                        items={filteredTeams}
                        columns={columns}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        onRenderRow={(props) => {
                            const customStyles = {
                                root: {
                                    cursor: 'pointer',
                                    selectors: {
                                        '&:hover': {
                                            backgroundColor: '#f3f2f1'
                                        }
                                    }
                                }
                            };
                            return <div style={customStyles.root}>{props.children}</div>;
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TeamsPage; 
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-detail {
  padding: 10px;
}

.email-subject {
  margin-bottom: 16px;
  font-weight: 600;
}

.email-metadata {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-date {
  color: #666;
  font-size: 12px;
}

.email-recipients {
  margin-bottom: 10px;
  color: #666;
}

.email-body {
  margin: 20px 0;
  max-height: 500px;
  overflow-y: auto;
}

.attachments-title {
  margin-bottom: 8px;
  font-weight: 600;
}

.email-actions {
  margin-top: 16px;
} `, "",{"version":3,"sources":["webpack://./src/pages/Emails/EmailDetail.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".email-detail {\r\n  padding: 10px;\r\n}\r\n\r\n.email-subject {\r\n  margin-bottom: 16px;\r\n  font-weight: 600;\r\n}\r\n\r\n.email-metadata {\r\n  margin-bottom: 10px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.email-date {\r\n  color: #666;\r\n  font-size: 12px;\r\n}\r\n\r\n.email-recipients {\r\n  margin-bottom: 10px;\r\n  color: #666;\r\n}\r\n\r\n.email-body {\r\n  margin: 20px 0;\r\n  max-height: 500px;\r\n  overflow-y: auto;\r\n}\r\n\r\n.attachments-title {\r\n  margin-bottom: 8px;\r\n  font-weight: 600;\r\n}\r\n\r\n.email-actions {\r\n  margin-top: 16px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

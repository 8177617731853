// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call-detail {
  padding: 20px;
}

.call-header {
  margin-bottom: 16px;
}

.call-subject {
  font-weight: 600;
}

.call-info {
  margin-top: 16px;
}

.info-section {
  margin-bottom: 24px;
}

.section-title {
  font-weight: 600;
  margin-bottom: 12px;
}

.participants-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.participant-item {
  padding: 12px;
  border-radius: 4px;
  background-color: #f5f5f5;
  transition: background-color 0.2s ease;
}

.participant-item:hover {
  background-color: #e8e8e8;
}

/* 로딩 컨테이너 */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/pages/CallRecords/CallDetail.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,YAAY;AACZ;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".call-detail {\r\n  padding: 20px;\r\n}\r\n\r\n.call-header {\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.call-subject {\r\n  font-weight: 600;\r\n}\r\n\r\n.call-info {\r\n  margin-top: 16px;\r\n}\r\n\r\n.info-section {\r\n  margin-bottom: 24px;\r\n}\r\n\r\n.section-title {\r\n  font-weight: 600;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.participants-list {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 8px;\r\n}\r\n\r\n.participant-item {\r\n  padding: 12px;\r\n  border-radius: 4px;\r\n  background-color: #f5f5f5;\r\n  transition: background-color 0.2s ease;\r\n}\r\n\r\n.participant-item:hover {\r\n  background-color: #e8e8e8;\r\n}\r\n\r\n/* 로딩 컨테이너 */\r\n.loading-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 300px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import {
  Stack,
  Text,
  Separator,
  Persona,
  PersonaSize,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  DefaultButton,
  TextField,
  ActionButton
} from '@fluentui/react';
import useGraphData from '../../hooks/useGraphData';
import './ChatDetail.css';

const ChatDetail = ({ chatId }) => {
  // 채팅 메시지 가져오기
  const { 
    data: messages, 
    loading: messagesLoading, 
    error: messagesError,
    refresh: refreshMessages
  } = useGraphData(`/chats/${chatId}/messages`);
  
  // 채팅 멤버 가져오기
  const { 
    data: members, 
    loading: membersLoading, 
    error: membersError 
  } = useGraphData(`/chats/${chatId}/members`);
  
  const isLoading = messagesLoading || membersLoading;
  const hasError = messagesError || membersError;
  
  if (isLoading) {
    return (
      <div className="loading-container">
        <Spinner size={SpinnerSize.large} label="대화 내용을 불러오는 중..." />
      </div>
    );
  }
  
  if (hasError) {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        오류가 발생했습니다: {messagesError || membersError}
      </MessageBar>
    );
  }
  
  // HTML 컨텐츠를 안전하게 표시
  const createMarkup = (html) => {
    return { __html: html || '<p>내용 없음</p>' };
  };
  
  // 날짜 포맷팅
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  return (
    <div className="chat-detail">
      <div className="chat-header">
        <DefaultButton
          iconProps={{ iconName: 'Refresh' }}
          text="새로고침"
          onClick={refreshMessages}
          className="refresh-button"
        />
      </div>
      
      <Separator />
      
      {/* 채팅 참여자 목록 */}
      <div className="chat-members">
        <Text variant="mediumPlus" className="section-title">참여자 ({members.length}명)</Text>
        <div className="members-list">
          {members.map(member => (
            <Persona
              key={member.id}
              text={member.displayName || '이름 없음'}
              secondaryText={member.email || '이메일 없음'}
              size={PersonaSize.size32}
              className="member-item"
            />
          ))}
        </div>
      </div>
      
      <Separator />
      
      {/* 채팅 메시지 목록 */}
      <div className="chat-messages">
        <Text variant="mediumPlus" className="section-title">메시지</Text>
        
        {messages.length === 0 ? (
          <Text className="no-messages">메시지가 없습니다.</Text>
        ) : (
          <div className="messages-list">
            {messages.map(message => (
              <div key={message.id} className="message-item">
                <div className="message-header">
                  <Persona
                    text={message.from?.user?.displayName || '이름 없음'}
                    size={PersonaSize.size24}
                  />
                  <Text className="message-time">{formatDate(message.createdDateTime)}</Text>
                </div>
                
                <div className="message-content">
                  {message.body?.contentType === 'html' ? (
                    <div dangerouslySetInnerHTML={createMarkup(message.body.content)} />
                  ) : (
                    <Text>{message.body?.content || '(내용 없음)'}</Text>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      
      <Separator />
      
      {/* 메시지 전송 폼 */}
      <div className="message-form">
        <TextField
          placeholder="메시지를 입력하세요..."
          multiline
          resizable={false}
          className="message-input"
        />
        <ActionButton
          iconProps={{ iconName: 'Send' }}
          text="보내기"
          className="send-button"
        />
      </div>
    </div>
  );
};

export default ChatDetail; 